import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { scheduleScreening } from 'shared/actions/screeningActions';
import { Button } from 'shared/components/ihcl/button';
import { trackClick, trackEvent, MixpanelEvent } from 'shared/helpers/tracking';
import { NurseProfile } from 'talent/types';

type ScheduleScreeningButtonProps = {
  buttonSize?: string;
  callToAction?: string;
  prePopup?: () => void;
  onScreeningScheduled?: () => void;
  onClick?: () => void;
  isLoading?: boolean;
  requestInProgress?: boolean;
  requestCompleted?: boolean;
  scheduleScreeningAction: (tpId: NurseProfile['id']) => void;
  talentProfile: NurseProfile;
  clickEventName?: string;
  eventProperties?: {
    [key: string]: any;
  };
};

const ScheduleScreeningButton = ({
  buttonSize = 'compact',
  callToAction = 'Pick an intro call time',
  prePopup = null,
  onScreeningScheduled = null,
  onClick = null,
  isLoading = false,
  requestInProgress = false,
  requestCompleted = false,
  scheduleScreeningAction,
  talentProfile,
  clickEventName = 'talent.screening.schedule_call',
  eventProperties = null,
  ...buttonProps
}: ScheduleScreeningButtonProps): JSX.Element => {
  const history = useHistory();

  useEffect(() => {
    trackEvent(MixpanelEvent.screeningPromptViewed, eventProperties);
  }, []);

  useEffect(() => {
    if (requestCompleted && onScreeningScheduled) {
      onScreeningScheduled();
    }
  }, [requestCompleted, onScreeningScheduled]);

  const updateHash = (newHash) => {
    history.push(`${history.location.pathname}${newHash}`);
  };

  const handleClick = () => {
    if (prePopup) {
      prePopup();
    }

    trackClick(clickEventName, {
      ...eventProperties,
      screening_type: talentProfile.screening_type,
    });

    if (onClick) {
      onClick();
    } else {
      updateHash('#introCall');
    }
  };

  return (
    <Button
      id="schedule-screening-button-wrapper"
      color="alternate"
      size={buttonSize}
      onClick={handleClick}
      disabled={requestInProgress}
      isLoading={requestInProgress || isLoading}
      {...buttonProps}
    >
      {callToAction}
    </Button>
  );
};

const mapStateToProps = (state, ownProps) => ({
  requestInProgress: state.uiState.get('scheduleScreeningRequestInProgress'),
  requestCompleted: state.uiState.get('scheduleScreeningRequestCompleted'),
  talentProfile: ownProps.talentProfile || state.talent_profile,
});

export default connect(mapStateToProps, {
  scheduleScreeningAction: scheduleScreening,
})(ScheduleScreeningButton);

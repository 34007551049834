import { authenticityToken } from './rails';
import { checkResponseStatus } from './action-utils';

export function doGet(url) {
  const headers = {
    Accept: 'application/json',
  };

  return fetch(url, {
    method: 'GET',
    headers,
    credentials: 'same-origin',
  })
    .then((resp) => checkResponseStatus(resp, { method: 'GET' }))
    .then((resp) => {
      if (resp) {
        return resp.json();
      }
      return undefined;
    });
}

export function doPost(url, params = {}, methodOverride = null) {
  const headers = {
    Accept: 'application/json',
    'X-CSRF-Token': authenticityToken(),
  };

  if (methodOverride) {
    headers['X-Http-Method-Override'] = methodOverride;
  }

  let body;
  if (params instanceof FormData) {
    body = params;
  } else {
    headers['Content-Type'] = 'application/json';
    body = JSON.stringify(params);
  }

  return fetch(url, {
    method: 'POST',
    headers,
    credentials: 'same-origin',
    body,
  })
    .then((resp) =>
      checkResponseStatus(resp, { method: methodOverride || 'POST' })
    )
    .then((resp) => {
      if (resp && resp.status !== 204) {
        return resp.json();
      }
      return undefined;
    });
}

export function doPut(url, params = {}) {
  return doPost(url, params, 'PUT');
}

export function doPatch(url, params = {}) {
  return doPost(url, params, 'PATCH');
}

export function doDelete(url, params = {}) {
  return doPost(url, params, 'DELETE');
}

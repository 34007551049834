import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'shared/components/ihcl/styled';

import { Button } from 'shared/components/ihcl/button';
import { doPost, doDelete } from 'shared/helpers/http';
import { Center } from 'shared/components/ihcl/positioning';
import { Input } from 'shared/components/ihcl/input';
import { Spinner } from 'shared/components/ihcl/spinner';
import { parsePhoneNumber } from 'shared/helpers/parsers';
import { trackClick } from 'shared/helpers/tracking';

import {
  Onboarding,
  OnboardingContext,
  OnboardingTitle,
  OnboardingText,
} from 'registration/containers/Onboarding';

import OnboardingTestimonial from './OnboardingTestimonial';

const SignInWrapper = styled('div', {
  marginTop: '32px',
});

const TextLoginWrapper = styled('div', {
  marginTop: '8px',
});

// eslint-disable-next-line react/require-default-props
const OnboardingPhone = ({
  nextAction = null,
  previousAction = null,
  stepNumber,
}) => {
  const { onboardingData, setOnboardingData } = useContext(OnboardingContext);
  const nextActionButtonRef = useRef(null);
  const [smsSent, setSMSSent] = useState(false);
  const [smsSending, setSMSSending] = useState(false);

  if (!onboardingData || Object.keys(onboardingData).length < 1) {
    return null;
  }
  const {
    errors,
    phone: { text: phoneText },
  } = onboardingData;

  let phoneError = null;
  if (errors && errors.phone && Array.isArray(errors.phone)) {
    phoneError = errors.phone[0];
  }

  const sendSMSLogin = (evt) => {
    evt.preventDefault();
    trackClick('Onboarding Duplicate Phone Send SMS Login');
    setSMSSending(true);
    doPost('/nurse_profile/send_sms_login', {}).then(() => {
      setSMSSent(true);
      setSMSSending(false);
    });
  };

  let smsLinkText = 'Text me a login link';
  if (smsSent) {
    smsLinkText = 'SMS Login Link sent. Retry?';
  }

  const setPhone = (phoneNumber, errorMessage) => {
    const phoneData = {
      phone: {
        text: phoneNumber,
      },
      disallow_duplicate_phone: true,
    };
    if (errorMessage) {
      const newErrors = {
        ...errors,
        phone: errorMessage,
      };
      setOnboardingData({
        ...onboardingData,
        ...phoneData,
        errors: newErrors,
      });
    } else if (errors) {
      const newErrors = Object.fromEntries(
        Object.entries(errors).filter(([key]) => key !== 'phone')
      );
      let newOnboardingData = {
        ...onboardingData,
        ...phoneData,
        errors: newErrors,
      };
      if (Object.keys(newErrors).length === 0) {
        newOnboardingData = Object.fromEntries(
          Object.entries(newOnboardingData).filter(([key]) => key !== 'errors')
        );
      }
      setOnboardingData(newOnboardingData);
    } else {
      setOnboardingData({
        ...onboardingData,
        ...phoneData,
      });
    }
  };

  const { referral_code: referralCode } = onboardingData;

  return (
    <Onboarding
      currentStep={stepNumber}
      isNextDisabled={
        !parsePhoneNumber(phoneText) || parsePhoneNumber(phoneText).length < 12
      }
      nextAction={nextAction}
      nextActionButtonRef={nextActionButtonRef}
      previousAction={previousAction}
      stepKeys={['phone', 'disallow_duplicate_phone']}
    >
      <form
        action="#"
        onSubmit={(evt) => {
          evt.preventDefault();
          if (
            parsePhoneNumber(phoneText) &&
            nextActionButtonRef &&
            nextActionButtonRef.current
          ) {
            nextActionButtonRef.current.click();
          }
        }}
      >
        <Center>
          <OnboardingTitle>What&apos;s your phone number?</OnboardingTitle>
          <Center>
            <OnboardingText>
              It&apos;s used for interview scheduling, and we take your privacy
              seriously.
            </OnboardingText>
            <Input
              value={phoneText}
              inputMode="tel"
              type="tel"
              label="Phone Number"
              onChange={(evt) => {
                setPhone(evt.target.value, null);
              }}
              onBlur={(evt) => {
                if (!parsePhoneNumber(evt.target.value)) {
                  setPhone(
                    evt.target.value,
                    'Please enter a valid phone number'
                  );
                }
              }}
              error={!!phoneError}
              caption={phoneError}
            />
            {phoneError === 'Looks like you already have an account' && (
              <>
                <SignInWrapper>
                  <Button
                    kind="minimal"
                    onClick={(evt) => {
                      evt.preventDefault();
                      trackClick('Onboarding Duplicate Phone Sign In');
                      doDelete('/users/sign_out', {}).then(() => {
                        window.location = `/users/sign_in?migrate_from=${referralCode}`;
                      });
                    }}
                  >
                    Sign In
                  </Button>
                </SignInWrapper>
                <TextLoginWrapper>
                  <Button
                    kind="minimal"
                    onClick={sendSMSLogin}
                    endEnhancer={smsSending && <Spinner />}
                  >
                    {smsLinkText}
                  </Button>
                </TextLoginWrapper>
              </>
            )}
            <OnboardingTestimonial
              attributionLine="#humbleBrag from:"
              author="Carley Hansen, RN"
              quote="I would definitely recommend this service! They are prompt, responsive, and a great resource. I was able to secure interviews and a job in less than 2 weeks."
            />
          </Center>
        </Center>
      </form>
    </Onboarding>
  );
};
OnboardingPhone.propTypes = {
  nextAction: PropTypes.string,
  previousAction: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
};

export default OnboardingPhone;

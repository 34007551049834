import React, { useContext, useRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import snakeCase from 'just-snake-case';
import { styled, withStyle } from 'shared/components/ihcl/styled';
import { Tag, KIND as TAG_KIND } from 'shared/components/ihcl/tag';

import { ButtonSelect, UNSELECTED } from 'shared/components/ihcl/button_select';
import { Center } from 'shared/components/ihcl/positioning';

import { Select } from 'shared/components/ihcl/select';

import type { Theme } from 'shared/components/ihcl/styled';

import {
  Onboarding,
  OnboardingSubtitle,
  OnboardingTitle,
  OnboardingContext,
} from 'registration/containers/Onboarding';

const IsolatedSteps = styled('div', () => ({ width: '100%' }));

const FirstIsolatedStep = styled('div', () => ({
  width: '100%',
}));

const IsolatedStep = withStyle(
  FirstIsolatedStep,
  ({
    $collapseTopPadding,
  }: {
    $theme: Theme;
    $collapseTopPadding: Boolean;
  }) => ({
    paddingTop: $collapseTopPadding ? 0 : '40px',
  })
);

const SelectedRoles = styled('div', ({ $theme }) => ({
  marginTop: $theme.sizing.unit16,
}));

// eslint-disable-next-line react/require-default-props
const OnboardingTechRoles = ({ stepNumber }) => {
  const {
    // @ts-ignore
    onboardingData,
    // @ts-ignore
    onboardingOptions,
    // @ts-ignore
    setOnboardingData,
  } = useContext(OnboardingContext);
  /* eslint-disable @typescript-eslint/naming-convention */
  const { techRoleOptions, tech_role_type } = onboardingData;
  const techRoleOptionsRef = useRef(null);

  const stepKeys = ['techRoleOptions'];

  const { tech_job_descriptions: techJobDescriptions } = onboardingOptions;

  const techRoleTypesSet = techJobDescriptions.reduce(
    (categorySet, jobDescription) => {
      categorySet.add(jobDescription.category);
      return categorySet;
    },
    new Set()
  );

  const techRoleTypes = Array.from(techRoleTypesSet).map(
    (category: string) => ({
      label: category,
      value: snakeCase(
        category
          .toLowerCase()
          .replace(/[^a-z ]/g, '')
          .replace(/\s\s+/g, ' ')
      ),
    })
  );

  const techRoleMap = techRoleTypes.reduce(
    (roleMap, roleType) => ({ ...roleMap, [roleType.value]: roleType.label }),
    {}
  );

  const unfilteredTechRoleOptions = techJobDescriptions.map(
    ({ category, id, title }) => ({
      category,
      label: title,
      value: id,
    })
  );

  const selectedRoleIds = (onboardingData?.techRoleOptions || []).map(
    (option) => option.value
  );

  const filteredTechRoleOptions = unfilteredTechRoleOptions.filter(
    (option) =>
      techRoleMap[tech_role_type] === option.category &&
      !selectedRoleIds.includes(option.value)
  );

  return (
    <Center>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, interactive-widget=overlays-content"
        />
      </Helmet>
      <Onboarding
        autoSpacedStepContents={false}
        centerStepContents
        isNextDisabled={
          !tech_role_type ||
          tech_role_type === UNSELECTED ||
          !techRoleOptions ||
          techRoleOptions?.length === 0
        }
        currentStep={stepNumber}
        stepKeys={stepKeys}
      >
        <OnboardingTitle $noBottomMargin>
          What type of tech roles are you interested in?
        </OnboardingTitle>
        <OnboardingSubtitle $smallTopMargin $largeBottomMargin>
          Please select one
        </OnboardingSubtitle>
        <IsolatedSteps>
          <FirstIsolatedStep>
            <ButtonSelect
              options={techRoleTypes}
              onChange={(optionValue) => {
                setOnboardingData({
                  ...onboardingData,
                  tech_role_type: optionValue || UNSELECTED,
                });
              }}
              selectedOptions={tech_role_type}
            />
          </FirstIsolatedStep>
          {tech_role_type && tech_role_type !== UNSELECTED && (
            <IsolatedStep ref={techRoleOptionsRef}>
              <OnboardingSubtitle>
                Please select all that apply
              </OnboardingSubtitle>
              <Select
                instanceId="techRoleOptions"
                multi
                clearable={false}
                labelKey="label"
                valueKey="value"
                options={filteredTechRoleOptions}
                onChange={(optionValue) => {
                  if (optionValue === null) {
                    const newOnboardingData = { ...onboardingData };
                    delete newOnboardingData.techRoleOptions;
                    setOnboardingData(newOnboardingData);
                    return;
                  }
                  const onboardingDataUpdate: any = {};
                  onboardingDataUpdate.techRoleOptions = [
                    ...(onboardingData?.techRoleOptions || []),
                    ...optionValue.value,
                  ];
                  setOnboardingData({
                    ...onboardingData,
                    ...onboardingDataUpdate,
                  });
                }}
              />
              <SelectedRoles>
                {techRoleOptions?.map((option) => (
                  <Tag
                    $fullWidth
                    key={option.value}
                    placeholder="Select..."
                    kind={TAG_KIND.accent}
                    variant="solid"
                    onClick={() => {
                      const onboardingDataUpdate: any = {};
                      onboardingDataUpdate.techRoleOptions =
                        techRoleOptions.filter(
                          (roleOption) => roleOption.value !== option.value
                        );
                      setOnboardingData({
                        ...onboardingData,
                        ...onboardingDataUpdate,
                      });
                    }}
                  >
                    {option.label}
                  </Tag>
                ))}
              </SelectedRoles>
            </IsolatedStep>
          )}
        </IsolatedSteps>
      </Onboarding>
    </Center>
  );
};
OnboardingTechRoles.propTypes = {
  stepNumber: PropTypes.number.isRequired,
};

export default OnboardingTechRoles;

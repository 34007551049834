import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStyletron } from 'shared/components/ihcl/styled';

import { Button } from 'shared/components/ihcl/button';
import { UNSELECTED } from 'shared/components/ihcl/button_select';
import { Center } from 'shared/components/ihcl/positioning';
import { Footnote, FootnoteIndicator } from 'shared/components/ihcl/footnote';

import {
  OnboardingContext,
  OnboardingTitle,
  OnboardingWrapper,
  sendUpdate,
} from '../../containers/Onboarding';
import isReactivationFlow from '../../helpers/isReactivationFlow';
import { testimonial as onboardingTestimonial } from './OnboardingTimelineIsolatedQuestions';

// eslint-disable-next-line react/require-default-props
const OnboardingPermanentJob = ({
  nextAction,
  stepNumber,
  // eslint-disable-next-line react/require-default-props
  isLiteProfileActivating,
}) => {
  const {
    history,
    onboardingBasePath,
    onboardingData,
    onboardingUpdates,
    profileType = 'nurse',
    setOnboardingData,
    setOnboardingUpdates,
  } = useContext(OnboardingContext);
  const [, $theme] = useStyletron();
  const setOnboardingFields = (jobSearchStatus) => {
    if (isReactivationFlow(onboardingBasePath)) {
      setOnboardingUpdates({
        ...onboardingUpdates,
        job_search_status: jobSearchStatus,
      });
    } else {
      setOnboardingData({
        ...onboardingData,
        job_search_status: jobSearchStatus,
      });
    }
  };

  useEffect(() => {
    const flowType = isReactivationFlow(onboardingBasePath)
      ? 'Reactivation'
      : 'Onboard';
    if (!isLiteProfileActivating) {
      setOnboardingFields(null);
      mixpanel.track(`${flowType} Step ${stepNumber} Started`);
    } else {
      setOnboardingFields('just_started');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      isReactivationFlow(onboardingBasePath) &&
      onboardingUpdates.job_search_status
    ) {
      sendUpdate({
        currentStep: stepNumber,
        history,
        nextAction,
        onboardingBasePath,
        onboardingData,
        onboardingUpdates,
        setOnboardingData,
      });
    } else if (
      !isReactivationFlow(onboardingBasePath) &&
      onboardingData.job_search_status
    ) {
      history.push(nextAction);
      const flowType = isReactivationFlow(onboardingBasePath)
        ? 'Reactivation'
        : 'Onboard';
      mixpanel.track(`${flowType} Step ${stepNumber} Completed`);
    }
  }, [
    history,
    nextAction,
    onboardingBasePath,
    onboardingData,
    onboardingUpdates,
    setOnboardingData,
    stepNumber,
  ]);

  const justifyContentStyle = { paddingTop: '78px' };

  return (
    <Center>
      <OnboardingWrapper
        $compactMaxWidth
        style={{ ...justifyContentStyle }}
        $justifyContent="space-between"
      >
        <div>
          <OnboardingTitle>
            Are you open to a permanent
            <FootnoteIndicator>*</FootnoteIndicator>{' '}
            {profileType === 'tech' ? 'healthcare tech' : 'nursing'} job?
          </OnboardingTitle>
          <div>
            <Button
              onClick={() => setOnboardingFields('not_looking')}
              kind="tertiary"
              style={{
                minWidth: '100px',
                marginRight: '32px',
              }}
            >
              No
            </Button>
            <Button
              onClick={() => {
                setOnboardingFields(
                  isReactivationFlow(onboardingBasePath)
                    ? 'just_started'
                    : UNSELECTED
                );
              }}
              kind="tertiary"
              style={{
                minWidth: '100px',
              }}
            >
              Yes
            </Button>
          </div>
          <Footnote
            backgroundColor={$theme.colors.onboardingBackground}
            styles={{ maxWidth: '230px' }}
          >
            Full time, part time, and per diem options available.
          </Footnote>
        </div>
        {onboardingTestimonial}
      </OnboardingWrapper>
    </Center>
  );
};
OnboardingPermanentJob.propTypes = {
  isLiteProfileActivating: PropTypes.bool,
  nextAction: PropTypes.string.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

OnboardingPermanentJob.defaultProps = {
  isLiteProfileActivating: false,
};

export default OnboardingPermanentJob;

import throttle from 'just-throttle';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { styled, useStyletron, withStyle } from 'shared/components/ihcl/styled';

import { Button } from 'shared/components/ihcl/button';
import { LocationPinIcon } from 'shared/components/ihcl/icon';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';
import { ProgressSteps } from 'shared/components/ihcl/progress_steps';
import { ToasterContainer, toaster } from 'shared/components/ihcl/toast';
import { doPatch } from 'shared/helpers/http';
import {
  trackProfileComplete,
  trackViewContent,
} from 'shared/helpers/tracking';

import { pathToVariant } from 'shared/helpers/activateABTest';
import isReactivationFlow from '../helpers/isReactivationFlow';
import {
  augmentState,
  convertStateToAttributes,
  convertToState,
} from '../helpers/talentProfileConversions';

export const OnboardingContext = React.createContext(0);

export const RestrictedWidthContainer = styled('div', {
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '960px',
  minWidth: '320px',
  width: '100%',
});

export const TagLocationPinIcon = () => (
  <LocationPinIcon
    height="15px"
    width="15px"
    style={{
      marginBottom: '-3px',
      marginRight: '6px',
    }}
  />
);

export const OnboardingWrapper = withStyle(
  RestrictedWidthContainer,
  ({
    $theme,
    $flushSides,
    $compactMaxWidth = false,
    $justifyContent = 'flex-start',
  }) => ({
    backgroundColor: $theme.colors.onboardingBackground,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: $justifyContent,
    height: 'calc(var(--vh, 1vh) * 100)',
    maxWidth: $compactMaxWidth ? '560px' : '960px',
    paddingTop: $compactMaxWidth ? $theme.sizing.unit8 : $theme.sizing.unit32,
    paddingRight: $flushSides ? 0 : $theme.sizing.unit16,
    paddingBottom: $theme.sizing.unit32,
    paddingLeft: $flushSides ? 0 : $theme.sizing.unit16,
    '@media screen and (max-width: 340px)': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  })
);

export const ProgressStepsWrapper = withStyle(
  RestrictedWidthContainer,
  ({ $theme, $flushWrapperSides, $accommodateBackButton = false }) => {
    const backButtonWrapperStyles = $accommodateBackButton
      ? {
          display: 'inline-flex',
          alignItems: 'center',
          gap: $theme.sizing.unit16,
        }
      : {};
    return {
      paddingRight: $flushWrapperSides ? $theme.sizing.unit16 : 0,
      paddingLeft: $flushWrapperSides ? $theme.sizing.unit16 : 0,
      boxShadow: `0 ${$theme.sizing.unit10} ${$theme.sizing.unit20} ${$theme.colors.onboardingBackground}`,
      ...backButtonWrapperStyles,
    };
  }
);

export const StepNavigation = withStyle(
  RestrictedWidthContainer,
  ({ $theme, $flushWrapperSides }) => ({
    position: 'relative',
    display: 'flex',
    paddingRight: $flushWrapperSides ? $theme.sizing.unit16 : 0,
    paddingLeft: $flushWrapperSides ? $theme.sizing.unit16 : 0,
    boxShadow: `0 -10px 20px ${$theme.colors.onboardingBackground}`,
    justifyContent: 'space-between',
  })
);

const StepContentsWrapper = styled('div', {
  height: '100%',
  overflow: 'auto',
  paddingBottom: '20px',
  ':after': {
    content: '',
    display: 'table',
    clear: 'both',
  },
});

const StepContentsInnerWrapper = withStyle(
  RestrictedWidthContainer,
  ({
    $autoSpacedStepContents,
    $centerStepContents,
    $customStepContentsStyle,
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: $centerStepContents ? 'center' : 'initial',
    justifyContent: $autoSpacedStepContents ? 'space-around' : 'normal',
    minHeight: '100%',
    ...$customStepContentsStyle,
  })
);

// eslint-disable-next-line react/require-default-props
export const StepContents = ({
  // eslint-disable-next-line react/require-default-props
  autoSpacedStepContents,
  // eslint-disable-next-line react/require-default-props
  centerStepContents,
  children,
  // eslint-disable-next-line react/require-default-props
  customStepContentsStyle,
}) => (
  <StepContentsWrapper>
    <StepContentsInnerWrapper
      $autoSpacedStepContents={autoSpacedStepContents}
      $centerStepContents={centerStepContents}
      $customStepContentsStyle={customStepContentsStyle}
    >
      {children}
    </StepContentsInnerWrapper>
  </StepContentsWrapper>
);
StepContents.propTypes = {
  autoSpacedStepContents: PropTypes.bool,
  centerStepContents: PropTypes.bool,
  children: PropTypes.node.isRequired,
  customStepContentsStyle: PropTypes.object,
};
StepContents.defaultProps = {
  autoSpacedStepContents: true,
  centerStepContents: false,
  customStepContentsStyle: null,
};

export const OnboardingTitle = styled('h2', ({ $theme, $noBottomMargin }) => {
  const baseStyles = $theme.typography.HeadingMedium;

  const marginTopStyle = { marginTop: $theme.sizing.unit40 };

  return {
    ...baseStyles,
    ...marginTopStyle,
    textAlign: 'center',
    marginBottom: $noBottomMargin ? 0 : $theme.sizing.unit40,
  };
});

export const OnboardingSubtitle = styled(
  'h3',
  ({
    $theme,
    $fontWeight,
    $noTopMargin,
    $smallTopMargin,
    $largeBottomMargin,
  }) => {
    let marginTop = $theme.sizing.unit40;
    if ($noTopMargin) {
      marginTop = 0;
    }
    if ($smallTopMargin) {
      marginTop = $theme.sizing.unit16;
    }
    return {
      ...$theme.typography.LabelMedium,
      fontWeight: $fontWeight || 'unset',
      marginBottom: $largeBottomMargin
        ? $theme.sizing.unit32
        : $theme.sizing.unit16,
      marginTop,
    };
  }
);

export const OnboardingText = styled('p', ({ $theme }) => ({
  color: $theme.colors.bodyCopyGray,
  fontSize: '18px',
  marginBottom: '32px',
}));

const OnboardingBackArrow = styled(LucideIcon, ({ $theme }) => ({
  color: $theme.colors.accent,
  marginLeft: $theme.sizing.unit6,
  marginRight: $theme.sizing.unit6,
}));

const OnboardingTopNavSpacer = styled('div', {
  minWidth: '44px',
  minHeight: '44px',
});

export const nextStepNumber = (currentStep) =>
  (parseInt(currentStep, 10) || 1) + 1;

export const getNextAction = (basePath, currentStep, totalSteps) =>
  `${basePath}/${Math.min(nextStepNumber(currentStep), totalSteps)}`;

export const getPreviousAction = (basePath, currentStep) =>
  `${basePath}/${Math.max(currentStep - 1, 1)}`;

export const sendUpdate = ({
  currentStep,
  history,
  nextAction,
  onboardingBasePath,
  onboardingData,
  onboardingUpdates,
  previousOnboardingUpdates,
  profileType,
  sharedEventProperties,
  setIsUpdating = () => {},
  setOnboardingData,
  setOnboardingUpdates,
  toasterUtil,
  totalSteps,
  formData,
  skipOnboardStepUpdate = false,
}) => {
  setIsUpdating(true);
  const navigateToNextAction = (nextActionUrl) => {
    if (nextActionUrl.indexOf(onboardingBasePath) === 0) {
      history.push(nextActionUrl);
    } else {
      window.location = new URL(nextActionUrl, window.location.origin);
    }
  };

  const flowType = isReactivationFlow(onboardingBasePath)
    ? 'Reactivation'
    : 'Onboard';
  const onboardingUpdatesWithStep = { ...onboardingUpdates };
  const nextStep = Number(nextAction.replace(`${onboardingBasePath}/`, ''));
  const savedStep = isReactivationFlow(onboardingBasePath)
    ? onboardingData.current_reactivation_step
    : onboardingData.current_onboard_step;

  if (
    !skipOnboardStepUpdate &&
    !Number.isNaN(nextStep) &&
    Number(nextStep) > 0 &&
    (!savedStep || nextStep > savedStep)
  ) {
    if (isReactivationFlow(onboardingBasePath)) {
      onboardingUpdatesWithStep.current_reactivation_step = nextStep;
    } else {
      onboardingUpdatesWithStep.current_onboard_step = nextStep;
    }
  }

  if (onboardingData.id) {
    if (onboardingUpdates && formData) {
      throw new Error(
        'Only supply one of onboardingUpdates or formData or ' +
          'else not all changes will be persisted'
      );
    }
    let updates;
    if (formData) {
      updates = formData;
      updates.append('include_conversion_multiplier', true);
    } else {
      updates = {
        ...onboardingUpdatesWithStep,
        include_conversion_multiplier: true,
      };
    }

    const type = profileType === 'tech' ? 'tech' : 'nurse';

    doPatch(`/${type}_profile/${onboardingData.id}`, updates)
      .then((resp) => {
        mixpanel.track(
          `${flowType} Step ${currentStep} Completed`,
          sharedEventProperties
        );
        let newOnboardingData = {
          ...onboardingData,
        };
        let onboardingDataUpdated = false;
        if (currentStep === 2) {
          trackViewContent(resp);
        }
        if (resp.nurse_specialties) {
          // The resume step relies on this data in the converted form.
          newOnboardingData = {
            ...newOnboardingData,
            nurse_specialties: resp.nurse_specialties,
            ...convertToState(resp, ['nurseSpecialties']),
          };
          onboardingDataUpdated = true;
        }
        if (resp.certifications) {
          newOnboardingData = {
            ...newOnboardingData,
            ...augmentState(resp, {}, ['certifications']),
          };
          onboardingDataUpdated = true;
        }
        if (resp.talent_advocate && !onboardingData.talent_advocate) {
          newOnboardingData = {
            ...newOnboardingData,
            talent_advocate: {
              ...resp.talent_advocate,
            },
          };
          onboardingDataUpdated = true;
        }
        if (resp.screen_status !== onboardingData.screen_status) {
          newOnboardingData.screen_status = resp.screen_status;
          onboardingDataUpdated = true;
        }
        if (resp.has_job_matches !== onboardingData.has_job_matches) {
          newOnboardingData.has_job_matches = resp.has_job_matches;
          onboardingDataUpdated = true;
        }
        if (resp.screening_type !== onboardingData.screening_type) {
          newOnboardingData.screening_type = resp.screening_type;
          onboardingDataUpdated = true;
        }
        if (
          !onboardingData.currentLocation ||
          resp.current_zip_code !== onboardingData.currentLocation.zip_code
        ) {
          const label = `${resp.current_city}, ${resp.current_state}`;
          newOnboardingData.currentLocation = {
            city: resp.current_city,
            state: resp.current_state,
            zip_code: resp.current_zip_code,
            label,
            id: `${label}, United States`,
          };
          onboardingDataUpdated = true;
        }
        if (
          resp.conversion_multiplier !== onboardingData.conversion_multiplier
        ) {
          newOnboardingData.conversion_multiplier = resp.conversion_multiplier;
          onboardingDataUpdated = true;
        }
        if (currentStep === totalSteps) {
          trackProfileComplete(resp);
        }
        if (onboardingDataUpdated && setOnboardingData) {
          setOnboardingData(newOnboardingData);
        }
        navigateToNextAction(nextAction);
      })
      .catch((error) => {
        setIsUpdating(false);
        if (error.response && error.response.status === 422) {
          error.response.json().then((json) => {
            if (json.errors && setOnboardingData) {
              setOnboardingData({
                ...onboardingData,
                errors: json.errors,
              });
            } else {
              console.error(json);
            }
          });
        } else {
          console.error(error);
          const errorMsg =
            'Oops, there was a problem connecting ' +
            'to the server. Please check your internet connection ' +
            'and try again, or refresh the page.';
          if (toasterUtil) {
            toasterUtil.negative(errorMsg);
          }
        }
      });
  } else {
    const newOnboardingData = {
      ...onboardingData,
    };
    if (typeof localforage !== 'undefined') {
      localforage.getItem(
        'onboardingUpdates',
        (err, localOnboardingUpdates) => {
          const newOnboardingUpdates = {
            ...localOnboardingUpdates,
            ...previousOnboardingUpdates,
            ...onboardingUpdatesWithStep,
          };
          localforage.setItem(
            'onboardingUpdates',
            newOnboardingUpdates,
            (setErr) => {
              if (setErr) {
                console.error('Local Onboarding Data Save Error:', setErr);
              }
              if (setOnboardingUpdates) {
                setOnboardingUpdates(newOnboardingUpdates);
              }
              if (setOnboardingData) {
                setOnboardingData(newOnboardingData);
              }
              mixpanel.track(
                `${flowType} Step ${currentStep} Completed`,
                sharedEventProperties
              );
              navigateToNextAction(nextAction);
            }
          );
        }
      );
    } else {
      if (setOnboardingUpdates) {
        const newOnboardingUpdates = {
          ...previousOnboardingUpdates,
          ...onboardingUpdatesWithStep,
        };
        setOnboardingUpdates(newOnboardingUpdates);
      }
      if (setOnboardingData) {
        setOnboardingData(newOnboardingData);
      }
      mixpanel.track(`${flowType} Step ${currentStep} Completed`);
      navigateToNextAction(nextAction);
    }
  }
};

const setVhProperty = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

// eslint-disable-next-line react/require-default-props
export const Onboarding = ({
  // eslint-disable-next-line react/require-default-props
  autoProgress,
  // eslint-disable-next-line react/require-default-props
  autoSpacedStepContents,
  // eslint-disable-next-line react/require-default-props
  centerStepContents,
  children,
  // eslint-disable-next-line react/require-default-props
  currentStep,
  // eslint-disable-next-line react/require-default-props
  customStepContentsStyle,
  flushWrapperSides = false,
  isNextDisabled,
  // eslint-disable-next-line react/require-default-props
  nextActionButtonRef,
  // eslint-disable-next-line react/require-default-props
  nextButtonSubmits,
  // eslint-disable-next-line react/require-default-props
  onboardingWrapper,
  // eslint-disable-next-line react/require-default-props
  onboardingWrapperProps,
  // eslint-disable-next-line react/require-default-props
  stepKeys,
  // eslint-disable-next-line react/require-default-props
  validateBeforeSubmit,
  ...props
}) => {
  const {
    onboardingData,
    onboardingUpdates: previousOnboardingUpdates,
    profileType,
    setOnboardingData,
    setOnboardingUpdates,
  } = useContext(OnboardingContext);
  let { onboardingBasePath } = useContext(OnboardingContext);
  let { nextAction, previousAction } = props;
  if (onboardingBasePath.endsWith('/')) {
    onboardingBasePath = onboardingBasePath.substring(
      0,
      onboardingBasePath.length - 1
    );
  }
  const { totalSteps, skippedSteps, priorStep, history } =
    useContext(OnboardingContext);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [, theme] = useStyletron();
  let setIsUpdating = setIsUpdatingProfile;
  if (autoProgress) {
    // setIsUpdating is only needed to disable the next button when the next
    // step is being loaded. Use a no-op when autoProgress is enabled.
    setIsUpdating = () => {};
  }

  const variant = pathToVariant(
    onboardingBasePath,
    profileType === 'tech' ? 'techOnboarding' : 'onboarding'
  );

  const sharedEventProperties = {
    onboard_variant: variant,
    profile_type: profileType,
  };

  useEffect(() => {
    const flowType = isReactivationFlow(onboardingBasePath)
      ? 'Reactivation'
      : 'Onboard';
    mixpanel.track(
      `${flowType} Step ${currentStep} Started`,
      sharedEventProperties
    );
    setVhProperty();
    // This prevents the container from being scrolled around on iOS
    document.documentElement.style.position = 'fixed';
    document.documentElement.style.height = '100%';
    document.documentElement.style.width = '100%';
    window.addEventListener(
      'resize',
      throttle(setVhProperty, 200, { leading: true })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!nextAction) {
    nextAction = getNextAction(onboardingBasePath, currentStep, totalSteps);
  }
  if (!previousAction) {
    previousAction = getPreviousAction(onboardingBasePath, currentStep);
  }
  // In some cases we want to explicitly prevent the back button from appearing
  // so that users can't go back to the Sign Up page. The # represents a null
  // action that we don't want to automatically populate based on currentStep.
  if (previousAction === '#') {
    previousAction = false;
  }

  const submitUpdate = () => {
    if (validateBeforeSubmit) {
      if (!validateBeforeSubmit()) {
        return;
      }
    }
    if (stepKeys.length) {
      const stepObj = stepKeys.reduce((acc, key) => {
        acc[key] = onboardingData[key];
        return acc;
      }, {});
      const onboardingUpdates = convertStateToAttributes(
        stepObj,
        Object.keys(stepObj),
        onboardingData
      );

      sendUpdate({
        currentStep,
        history,
        nextAction,
        onboardingBasePath,
        onboardingData,
        onboardingUpdates,
        previousOnboardingUpdates,
        profileType,
        sharedEventProperties,
        setIsUpdating,
        setOnboardingData,
        setOnboardingUpdates,
        toasterUtil: toaster,
        totalSteps,
      });
    }
  };

  if (autoProgress && !isNextDisabled) {
    submitUpdate(totalSteps);
  }

  if (onboardingWrapper) {
    const Wrapper = onboardingWrapper;
    return (
      <Wrapper
        $flushSides={flushWrapperSides}
        $compactMaxWidth
        $wrapperProps={{
          ...onboardingWrapperProps,
          isNextDisabled,
          validateBeforeSubmit,
        }}
      >
        {children}
      </Wrapper>
    );
  }

  return (
    <OnboardingWrapper $flushSides={flushWrapperSides} $compactMaxWidth>
      {totalSteps > 0 && (
        <ProgressStepsWrapper $accommodateBackButton>
          {previousAction && (
            <Button
              type="button"
              kind="minimal"
              color="alternate"
              onClick={() => {
                history.push(previousAction, { back: true });
              }}
              padding="tiny"
              data-testid="onboarding-compact-back-button"
            >
              <OnboardingBackArrow name="ArrowLeft" />
            </Button>
          )}
          {!previousAction && <OnboardingTopNavSpacer />}
          <ProgressSteps
            currentStep={currentStep}
            totalSteps={totalSteps}
            skippedSteps={skippedSteps}
            celebrateLastStep={priorStep && priorStep < currentStep}
          />
          <OnboardingTopNavSpacer />
        </ProgressStepsWrapper>
      )}
      <ToasterContainer />
      <StepContents
        autoSpacedStepContents={autoSpacedStepContents}
        centerStepContents={centerStepContents}
        customStepContentsStyle={customStepContentsStyle}
      >
        {children}
      </StepContents>
      <StepNavigation $flushWrapperSides={flushWrapperSides}>
        {!previousAction && <span />}
        {!autoProgress && (
          <Button
            fullWidth
            forwardedRef={nextActionButtonRef}
            type={nextButtonSubmits ? 'submit' : 'button'}
            color="alternate"
            disabled={isNextDisabled || isUpdatingProfile}
            $isLoading={isUpdatingProfile}
            onClick={() => submitUpdate(totalSteps)}
            style={{ textWrap: 'none' }}
          >
            Next
            <LucideIcon
              name="ArrowRight"
              style={{ marginLeft: theme.sizing.unit8 }}
            />
          </Button>
        )}
        {autoProgress && <span />}
      </StepNavigation>
    </OnboardingWrapper>
  );
};
Onboarding.propTypes = {
  autoProgress: PropTypes.bool,
  autoSpacedStepContents: PropTypes.bool,
  centerStepContents: PropTypes.bool,
  children: PropTypes.node.isRequired,
  currentStep: PropTypes.number,
  customStepContentsStyle: PropTypes.object,
  flushWrapperSides: PropTypes.bool,
  isNextDisabled: PropTypes.bool.isRequired,
  nextAction: PropTypes.string,
  nextActionButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  nextButtonSubmits: PropTypes.bool,
  onboardingWrapper: PropTypes.func,
  onboardingWrapperProps: PropTypes.object,
  previousAction: PropTypes.string,
  skipOnboardStepUpdate: PropTypes.bool,
  stepKeys: PropTypes.arrayOf(PropTypes.string),
  validateBeforeSubmit: PropTypes.func,
};
Onboarding.defaultProps = {
  autoProgress: false,
  autoSpacedStepContents: false,
  centerStepContents: false,
  currentStep: 1,
  customStepContentsStyle: null,
  flushWrapperSides: false,
  nextAction: null,
  nextActionButtonRef: null,
  nextButtonSubmits: false,
  onboardingWrapper: null,
  onboardingWrapperProps: {},
  previousAction: null,
  skipOnboardStepUpdate: false,
  stepKeys: [],
  validateBeforeSubmit: null,
};

import React from 'react';
import { connect } from 'react-redux';

import { updateTalentProfileComplete } from 'shared/actions/talentProfileActions';
import { trackClick } from 'shared/helpers/tracking';
import { useUpdateTalentProfile } from 'api/talent/hooks/talentProfile';

import { toaster } from 'shared/components/ihcl/toast';
import { styled } from 'shared/components/ihcl/styled';
import { Button } from 'shared/components/ihcl/button';
import { ModalHeader } from 'shared/components/ihcl/modal';
import { Text } from 'shared/components/ihcl/text';

import { NurseProfile } from 'talent/types';

import thoughtfulNurse from 'images/thoughtful-nurse.svg';

import Title from './Title';
import { ExpandingModalBody, FixedModalFooter } from './styledComponents';

const Image = styled('img', () => ({
  width: '240px',
  alignSelf: 'center',
}));

function RecentGradPreCheck({
  talentProfile,
  closeModal,
  dispatch,
}: {
  talentProfile: NurseProfile;
  closeModal: (eventName: string) => void;
  dispatch: (action: any) => void;
}) {
  const { mutate: updateTalentProfile, isLoading: isUpdating } =
    useUpdateTalentProfile({
      onSuccess: (resp) => {
        dispatch(updateTalentProfileComplete(resp));
      },
      onError: (error) => {
        console.error('Failed to update talent profile', error);
        toaster.negative(
          'Oops, there was a problem while updating your profile. Please ' +
            'check your internet connection and try again, or refresh the page.'
        );
      },
    });

  const updateRecentGrad = (isRecentGrad: boolean) => {
    trackClick('talent.screening.recent_grad.pre_check.update_recent_grad', {
      isRecentGrad,
    });

    if (isRecentGrad) {
      closeModal('talent.screening.recent_grad.pre_check.still_recent_grad');
      return;
    }

    updateTalentProfile({
      talentProfileId: talentProfile.id,
      body: {
        recent_grad: isRecentGrad,
      },
    });
  };

  return (
    <>
      <ModalHeader>
        <Title
          onClick={() =>
            closeModal('talent.screening.recent_grad.pre_check.close')
          }
        >
          Any work experience updates?
        </Title>
      </ModalHeader>
      <ExpandingModalBody>
        <Image src={thoughtfulNurse} />
        <Text variant="ParagraphMedium">
          We&apos;re excited to help you find your next nursing job. How long
          have you been working as an RN?
        </Text>
      </ExpandingModalBody>
      <FixedModalFooter>
        <Button
          size="small"
          color="alternate"
          kind="tertiary"
          padding="minimal"
          disabled={isUpdating}
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => updateRecentGrad(true)}
        >
          &lt; 10 months
        </Button>
        <Button
          size="default"
          color="alternate"
          kind="primary"
          padding="minimal"
          fullWidth
          isLoading={isUpdating}
          onClick={() => updateRecentGrad(false)}
        >
          More than 10 months
        </Button>
      </FixedModalFooter>
    </>
  );
}

export default connect(null, (dispatch) => ({
  dispatch,
}))(RecentGradPreCheck);

import { MutationFunction, useMutation } from '@tanstack/react-query';

export function baseMutation<T>(mutationFn: MutationFunction) {
  return ({
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: T) => void;
    onError?: (err: any) => void;
  }) =>
    useMutation({
      mutationFn,
      onSuccess,
      onError,
    });
}

export function conditionalBaseMutation<T>(
  matchingMutationFn: MutationFunction,
  nonMatchingMutationFn: MutationFunction
) {
  return ({
    condition,
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: T) => void;
    onError?: (err: any) => void;
    condition: boolean;
  }) =>
    useMutation({
      mutationFn: condition ? matchingMutationFn : nonMatchingMutationFn,
      onSuccess,
      onError,
    });
}

import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Button } from 'shared/components/ihcl/button';
import { Stack } from 'shared/components/ihcl/patterns';
import { doPost } from 'shared/helpers/http';
import { NurseProfile } from 'talent/types';
import { scheduleScreening } from 'shared/actions/screeningActions';
import { ModalHeader } from 'shared/components/ihcl/modal';
import { WrappedSpinner } from 'shared/components/ihcl/spinner';
import { Text } from 'shared/components/ihcl/text';
import AppointmentSelection from './AppointmentSelection';
import {
  BoldText,
  ExpandingModalBody,
  FixedModalFooter,
  PrimaryCTAWrapper,
} from './styledComponents';
import { getEndDate } from './helpers';
import Title from './Title';
import useScreeningOptions from './hooks/useScreeningOptions';

export function PickADifferentTime({
  talentProfile,
  scheduleScreeningAction,
  noTimesWorkAction,
  confirm,
  back,
}: {
  talentProfile: NurseProfile;
  scheduleScreeningAction: (id: number) => {};
  noTimesWorkAction: () => void;
  confirm: (eventName: string) => void;
  back: () => void;
}) {
  const [selectedDatetime, setSelectedDatetime] = useState(null);
  const { isLoading, availableTimes } = useScreeningOptions();
  const hasAvailableTimes = !isLoading && availableTimes.length > 0;
  const { screening } = talentProfile;
  const [isRescheduling, setIsRescheduling] = useState(false);

  return (
    <>
      <ModalHeader>
        <Title>Pick a different time</Title>
      </ModalHeader>
      <ExpandingModalBody>
        <Stack $gapSizing="unit10" $flexDirection="column">
          <Text variant="ParagraphMedium">
            Please select a reserved time window for us to connect with you
            about your job matches - the call itself won’t take more than{' '}
            <BoldText>10 minutes</BoldText>.
          </Text>
        </Stack>
        {isLoading && <WrappedSpinner spinnerSize="large" />}
        {!isLoading && availableTimes && (
          <AppointmentSelection
            selectedDatetime={selectedDatetime}
            setSelectedDatetime={setSelectedDatetime}
            noTimesWorkAction={noTimesWorkAction}
            availableTimes={availableTimes}
          />
        )}
      </ExpandingModalBody>
      {!isLoading && !hasAvailableTimes && (
        <FixedModalFooter>
          <Button
            onClick={() => back()}
            size="default"
            color="alternate"
            kind="tertiary"
            fullWidth
          >
            Back
          </Button>
        </FixedModalFooter>
      )}
      {hasAvailableTimes && (
        <FixedModalFooter>
          <Button
            onClick={() => back()}
            size="default"
            color="alternate"
            kind="tertiary"
            isLoading={isRescheduling}
          >
            Back
          </Button>
          <PrimaryCTAWrapper>
            <Button
              onClick={() => {
                setIsRescheduling(true);
                const updates = {
                  starts_at: selectedDatetime.toISOString(),
                  ends_at: getEndDate(selectedDatetime).toISOString(),
                  phone: talentProfile.screening.phone.tel_uri,
                  email: talentProfile.screening.email,
                  notes: talentProfile.screening.notes,
                };
                doPost(
                  `/appointment_window_screenings/${screening.id}/reschedule`,
                  updates
                )
                  .then(() => {
                    scheduleScreeningAction(talentProfile.id);
                  })
                  .then(() => {
                    confirm('talent.screening.picked_different_time');
                  })
                  .finally(() => setIsRescheduling(false));
              }}
              size="default"
              color="alternate"
              kind="primary"
              isLoading={isRescheduling}
              fullWidth
              disabled={!selectedDatetime}
            >
              Confirm window
            </Button>
          </PrimaryCTAWrapper>
        </FixedModalFooter>
      )}
    </>
  );
}

export default connect(null, {
  scheduleScreeningAction: scheduleScreening,
})(PickADifferentTime);

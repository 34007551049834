import React, { useContext, useEffect, useState } from 'react';
import { Set as ImmutableSet } from 'immutable';
import { Provider as StyletronProvider, DebugEngine } from 'styletron-react';
import { Provider } from 'react-redux';
import { Client as Styletron } from 'styletron-engine-atomic';
import get from 'just-safe-get';
import QueryClientProvider from 'shared/providers/QueryClientProvider';

import { incredibleTheme } from 'shared/components/ihcl';
import { NO_ACTIVE_MODAL } from 'shared/components/PopUpManager';
import { getExperimentAssignmentFromFeatureFlags } from 'shared/helpers/experiment';
import { trackExperimentAssignment } from 'shared/helpers/tracking';
import { QueryClient } from '@tanstack/react-query';
import BaseProvider from './ihcl/base-provider';

const defaultEngine = new Styletron({ prefix: 'ih-' });
const DELETE_KEY = '_DELETE_';

const ReduxProvider = ({
  children = null,
  store = null,
}: {
  children?: JSX.Element;
  store?: any;
}): JSX.Element =>
  store ? (
    <Provider store={store}>
      <>{children}</>
    </Provider>
  ) : (
    children
  );

type ActiveModal = {
  modalName: string;
  setModalName(name: string): void;
  hasActiveModal: boolean;
};

type AppContextType = {
  featureFlags?: ImmutableSet<string>;
  nurse_specialty_descriptions?: any[];
  nurse_specialty_skills?: any;
  states?: any[];
  talent_education_degree_types?: any[];
  nurseSpecialtyDescriptionsById?: any;
  nurseSpecialtySkillsById?: any;
  activeModal?: ActiveModal;
  techProfile?: any;
  'talent_profile.id'?: number;
  'techProfile.id'?: number;
};

type AppContextUpdaterType = {
  updateAppContext: (key: string, value: any) => void;
  updateProfile: (value: any) => void;
};

export const AppContext = React.createContext<AppContextType>({});
export const AppContextUpdater =
  React.createContext<AppContextUpdaterType>(null);
export const useAppContext = () => useContext(AppContext);
export const useAppContextUpdater = () => useContext(AppContextUpdater);
export const useAppContextFeatureFlags = () =>
  useContext(AppContext).featureFlags;

// more info on usage and best practices here:
// https://www.notion.so/incrediblehealth/Experiments-721b6079cbf64089bf7d808407afd910?pvs=4
export const useExperimentAssignment = ({
  experimentName,
  actorId = null,
  additionalEventProperties = {},
  withTracking = true,
}) => {
  const featureFlags = useAppContextFeatureFlags();
  const variantName = featureFlags
    ? getExperimentAssignmentFromFeatureFlags(
        featureFlags,
        experimentName,
        actorId
      )
    : null;

  useEffect(() => {
    if (withTracking && featureFlags) {
      trackExperimentAssignment(
        experimentName,
        variantName,
        additionalEventProperties
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return variantName;
};

const urlContextValues = (contextKey) => {
  // This currently only works for 'featureFlags' and we should be very
  // intentional about expanding the functionality here.
  if (!window.location.hash || contextKey !== 'featureFlags') {
    return null;
  }
  const urlParams = new URLSearchParams(window.location.hash.substring(1));
  let returnValue = null;
  urlParams.forEach((value, key) => {
    if (key === contextKey) {
      try {
        returnValue = JSON.parse(value);
      } catch (e) {
        returnValue = value;
      }
    }
  });
  return returnValue;
};

const AppProvider = ({
  children,
  engine = defaultEngine,
  store = null,
  queryClient = null,
  appContextKeys = [],
  activeModal = null,
  theme = incredibleTheme,
}: {
  children: React.ReactNode;
  engine?: Styletron;
  store?: any;
  queryClient?: QueryClient;
  appContextKeys?: string[];
  activeModal?: ActiveModal;
  theme?: any;
}) => {
  let storeState = {};
  let appContext: AppContextType = {};

  const [modalName, setModalName] = useState(null);

  if (store) {
    storeState = store.getState();
    // Making this opt-in for now, adding read-only keys as we need them
    appContext = appContextKeys.reduce((contextObj, contextKey) => {
      let contextData: ImmutableSet<any> = get(storeState, contextKey);
      const urlContextData = urlContextValues(contextKey);
      if (urlContextData) {
        if (contextData instanceof ImmutableSet) {
          if (Array.isArray(urlContextData)) {
            urlContextData.forEach((value) => {
              if (value.startsWith(DELETE_KEY)) {
                contextData = contextData.delete(
                  value.substring(DELETE_KEY.length)
                );
              } else {
                contextData = contextData.add(value);
              }
            });
          }
          if (typeof urlContextData === 'string') {
            if (urlContextData.startsWith(DELETE_KEY)) {
              contextData = contextData.delete(
                urlContextData.substring(DELETE_KEY.length)
              );
            } else {
              contextData = contextData.add(urlContextData);
            }
          }
        }
      }
      return {
        ...contextObj,
        [contextKey]: contextData,
      };
    }, {});

    if (appContextKeys.includes('nurse_specialty_descriptions')) {
      appContext.nurseSpecialtyDescriptionsById =
        appContext.nurse_specialty_descriptions.reduce(
          (specialtyMap, specialty) => ({
            ...specialtyMap,
            [specialty.id]: specialty,
          }),
          {}
        );
    }
    if (appContextKeys.includes('nurse_specialty_skills')) {
      appContext.nurseSpecialtySkillsById = Object.values(
        appContext.nurse_specialty_skills
      )
        .flatMap((skill) => skill)
        .reduce(
          (skillMap: any, skill: any) => ({
            ...skillMap,
            [skill.id]: skill,
          }),
          {}
        );
    }
  }

  const client = queryClient ?? new QueryClient();

  appContext.activeModal = activeModal || {
    modalName,
    setModalName,
    hasActiveModal: modalName && modalName !== NO_ACTIVE_MODAL,
  };
  const debug =
    process.env.NODE_ENV === 'production' ? null : new DebugEngine();
  return (
    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
      <BaseProvider theme={theme}>
        <AppContextUpdater.Provider
          value={{
            updateAppContext: (key, value) => {
              appContext[key] = value;
            },
            updateProfile: (value) => {
              if ('techProfile' in appContext) {
                appContext.techProfile = value;
              }
            },
          }}
        >
          <AppContext.Provider value={appContext}>
            <ReduxProvider store={store}>
              <QueryClientProvider queryClient={client}>
                {children}
              </QueryClientProvider>
            </ReduxProvider>
          </AppContext.Provider>
        </AppContextUpdater.Provider>
      </BaseProvider>
    </StyletronProvider>
  );
};

export default AppProvider;

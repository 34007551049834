import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Button } from 'shared/components/ihcl/button';
import { Input } from 'shared/components/ihcl/input';
import { styled } from 'shared/components/ihcl/styled';
import { Textarea } from 'shared/components/ihcl/textarea';
import { doPost } from 'shared/helpers/http';
import { NurseProfile } from 'talent/types';
import { scheduleScreening } from 'shared/actions/screeningActions';
import { getShortTimezone } from 'shared/helpers/timezones';
import { parsePhoneNumber } from 'shared/helpers/parsers';
import { validateEmail } from 'shared/helpers/validations';

import { toaster } from 'shared/components/ihcl/toast';

import { LucideIcon } from 'shared/components/ihcl/lucideIcon';
import { Stack } from 'shared/components/ihcl/patterns';
import { InputStack } from './styledComponents';
import AppointmentDetail from './AppointmentDetail';
import { getEndDate, formattedDateTime } from './helpers';

const CTAButtonWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  gap: $theme.sizing.unit8,
  width: '100%',
}));

export function AppointmentConfirmation({
  date,
  scheduleScreeningAction,
  talentProfile,
  closeModal,
  goBack,
}: {
  date: Date;
  scheduleScreeningAction: Function;
  talentProfile: NurseProfile;
  closeModal: Function;
  goBack: Function;
}) {
  const [email, setEmail] = useState(talentProfile.email);
  const [emailError, setEmailError] = useState('');
  const [phone, setPhone] = useState(talentProfile.phone?.text);
  const [phoneError, setPhoneError] = useState(null);
  const [notes, setNotes] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const parsedPhone = parsePhoneNumber(phone);

  return (
    <InputStack $flexDirection="column" $gapSizing="unit12">
      <AppointmentDetail
        label={`Time window selected (${getShortTimezone()})`}
        copy={formattedDateTime(date)}
      />
      <Input
        label="Email to receive calendar invite"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          setEmailError(null);
        }}
        onBlur={() => {
          setEmailError(validateEmail(email));
        }}
        size="default"
        tinyLabel="email"
        type="email"
        error={!!emailError}
        caption={emailError}
      />
      <Input
        label="Number to receive call"
        value={phone}
        onChange={(e) => {
          setPhone(e.target.value);
          setPhoneError(null);
        }}
        onBlur={() => {
          if (!parsedPhone) {
            setPhoneError('Please enter a valid phone number');
          }
        }}
        size="default"
        tinyLabel="phone"
        type="tel"
        inputMode="tel"
        error={!!phoneError}
        caption={phoneError}
      />
      <Textarea
        placeholder="(Optional) Any note you want to add"
        value={notes || ''}
        onChange={(e) => setNotes(e.target.value)}
        size="default"
        tinyLabel="note"
      />
      <CTAButtonWrapper>
        <Button
          onClick={() => goBack()}
          size="default"
          color="alternate"
          kind="tertiary"
        >
          Back
        </Button>
        <Button
          onClick={() => {
            setIsSubmitting(true);
            const updates = {
              starts_at: date.toISOString(),
              ends_at: getEndDate(date).toISOString(),
              email,
              phone: parsedPhone,
              notes: notes || null,
            };
            doPost('/appointment_window_screenings', updates)
              .then(() => scheduleScreeningAction(talentProfile.id))
              .then(() => {
                toaster.info(
                  <Stack $flexDirection="row" $gapSizing="scale500">
                    <LucideIcon name="Check" size="unit24" />
                    <div>Sent invite for your intro call</div>
                  </Stack>
                );
              })
              .then(() => {
                if (window.Intercom) {
                  window.Intercom('update', {
                    hide_default_launcher: true,
                  });
                }
                closeModal('talent.screening.scheduled');
              });
          }}
          size="default"
          color="alternate"
          kind="primary"
          fullWidth
          isLoading={isSubmitting}
          disabled={!phone || !email || !!(phoneError || emailError)}
        >
          Schedule
        </Button>
      </CTAButtonWrapper>
    </InputStack>
  );
}

export default connect(null, {
  scheduleScreeningAction: scheduleScreening,
})(AppointmentConfirmation);

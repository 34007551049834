import React, { useState } from 'react';
import { connect } from 'react-redux';

import { getTalentProfile } from 'shared/actions/talentProfileActions';

import { Button } from 'shared/components/ihcl/button';
import { Input } from 'shared/components/ihcl/input';
import { ModalHeader } from 'shared/components/ihcl/modal';
import { Text } from 'shared/components/ihcl/text';
import { Textarea } from 'shared/components/ihcl/textarea';
import { doPut } from 'shared/helpers/http';
import { parsePhoneNumber } from 'shared/helpers/parsers';
import { getShortTimezone } from 'shared/helpers/timezones';
import { validateEmail } from 'shared/helpers/validations';
import { NurseProfile } from 'talent/types';

import AppointmentDetail from './AppointmentDetail';
import Title from './Title';
import { formattedDateTime } from './helpers';
import {
  BoldText,
  ExpandingModalBody,
  FixedModalFooter,
  InputStack,
  PrimaryCTAWrapper,
} from './styledComponents';

function UpdateContactInfoModal({
  talentProfile,
  closeModal,
  getTalentProfileAction,
}: {
  talentProfile: NurseProfile;
  closeModal: (eventName: string) => void;
  getTalentProfileAction: (id: number) => {};
}) {
  const { screening } = talentProfile;

  const [email, setEmail] = useState(screening.email || '');
  const [emailError, setEmailError] = useState('');
  const [phone, setPhone] = useState(screening.phone?.text);
  const [phoneError, setPhoneError] = useState(null);
  const [notes, setNotes] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const parsedPhone = parsePhoneNumber(phone);

  return (
    <>
      <ModalHeader>
        <Title
          onClick={() => closeModal('talent.screening.contact_info.close')}
        >
          Your upcoming intro call contact details
        </Title>
      </ModalHeader>
      <ExpandingModalBody>
        <Text variant="ParagraphMedium">
          An Incredible Health team member will call during your reserved time
          window to chat through your job matches - the call itself won’t take
          more than <BoldText>10 minutes</BoldText>.
        </Text>
        <InputStack $flexDirection="column" $gapSizing="unit12">
          <AppointmentDetail
            label={`Time window selected (${getShortTimezone()})`}
            copy={formattedDateTime(new Date(screening.starts_at))}
          />
          <Input
            label="Email to receive calendar invite"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(null);
            }}
            onBlur={() => {
              setEmailError(validateEmail(email));
            }}
            size="default"
            tinyLabel="email"
            type="email"
            error={!!emailError}
            caption={emailError}
          />
          <Input
            label="Number to receive call"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setPhoneError(null);
            }}
            onBlur={() => {
              if (!parsedPhone) {
                setPhoneError('Please enter a valid phone number');
              }
            }}
            size="default"
            tinyLabel="phone"
            type="tel"
            inputMode="tel"
            error={!!phoneError}
            caption={phoneError}
          />
          <Textarea
            placeholder="(Optional) Any note you want to add"
            value={notes || ''}
            onChange={(e) => setNotes(e.target.value)}
            size="default"
            tinyLabel="note"
          />
        </InputStack>
      </ExpandingModalBody>
      <FixedModalFooter>
        <PrimaryCTAWrapper>
          <Button
            onClick={() => {
              setIsSubmitting(true);
              const updates = {
                email,
                phone: parsedPhone,
                notes: notes || null,
              };
              doPut(`/appointment_window_screenings/${screening.id}`, updates)
                .then(() => getTalentProfileAction(talentProfile.id))
                .then(() => setIsSubmitting(false))
                .finally(() =>
                  closeModal('talent.screening.contact_info.updated')
                );
            }}
            size="default"
            color="alternate"
            kind="primary"
            fullWidth
            isLoading={isSubmitting}
            disabled={!phone || !email || !!(phoneError || emailError)}
          >
            Update my contact info
          </Button>
        </PrimaryCTAWrapper>
      </FixedModalFooter>
    </>
  );
}

export default connect(null, {
  getTalentProfileAction: getTalentProfile,
})(UpdateContactInfoModal);

import PropTypes from 'prop-types';
import React, { lazy, Suspense } from 'react';
import { styled } from 'shared/components/ihcl/styled';

import {
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Row,
} from 'react-bootstrap';
import { Spinner } from 'shared/components/ihcl/spinner';

const PasswordStrength = lazy(
  () =>
    import(
      /* webpackChunkName: "password_strength" */
      'shared/components/PasswordStrength'
    )
);

const PasswordStrengthWrapper = styled('div', {
  marginTop: '16px',
  marginLeft: '145px',
});

const EmployerProfileDeprecatedForm = function (props) {
  return (
    <>
      <FormGroup
        controlId="full-name"
        validationState={props.errors.get('fullName') ? 'error' : null}
      >
        <Row className="top-separator center-row">
          <ControlLabel>
            <span>Full Name</span>
          </ControlLabel>
          <FormControl
            autoFocus
            name="user[employer_profile_attributes][full_name]"
            onChange={props.onTextFieldChange}
            type="text"
            value={props.fullName}
          />
        </Row>
        <Row>
          <HelpBlock>{props.errors.get('fullName', '')}</HelpBlock>
        </Row>
      </FormGroup>

      <FormGroup
        controlId="email"
        validationState={props.errors.get('email') ? 'error' : null}
      >
        <Row className="top-buffer center-row">
          <ControlLabel>
            <span>Email</span>
          </ControlLabel>
          <FormControl
            name="user[email]"
            onChange={props.onTextFieldChange}
            type="text"
            value={props.email}
          />
        </Row>
        <Row>
          <HelpBlock>{props.errors.get('email', '')}</HelpBlock>
        </Row>
      </FormGroup>

      <FormGroup
        controlId="password"
        validationState={props.errors.get('password') ? 'error' : null}
      >
        <Row className="top-buffer center-row">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            name="user[password]"
            onChange={props.onTextFieldChange}
            type="password"
            value={props.password}
          />
        </Row>
        <PasswordStrengthWrapper>
          <Suspense fallback={<Spinner $size="small" />}>
            <PasswordStrength
              password={props.password}
              onPasswordScoreChange={props.onPasswordScoreChange}
            />
          </Suspense>
        </PasswordStrengthWrapper>
      </FormGroup>

      <FormGroup
        controlId="phone"
        validationState={props.errors.get('phone') ? 'error' : null}
      >
        <Row className="top-buffer center-row">
          <ControlLabel>Phone Number</ControlLabel>
          <FormControl
            name="user[employer_profile_attributes][phone_and_extension]"
            onChange={props.onTextFieldChange}
            type="text"
            value={props.phone}
          />
        </Row>
        <Row>
          <HelpBlock>
            <span className="sentry-mask">{props.errors.get('phone', '')}</span>
          </HelpBlock>
        </Row>
      </FormGroup>

      <FormGroup
        controlId="job-title"
        validationState={props.errors.get('jobTitle') ? 'error' : null}
      >
        <Row className="top-buffer center-row">
          <ControlLabel>Job Title</ControlLabel>
          <FormControl
            name="user[employer_profile_attributes][job_title]"
            onChange={props.onTextFieldChange}
            type="text"
            value={props.jobTitle}
          />
        </Row>
        <Row>
          <HelpBlock>{props.errors.get('jobTitle', '')}</HelpBlock>
        </Row>
      </FormGroup>

      <FormGroup
        controlId="company-name"
        validationState={props.errors.get('companyName') ? 'error' : null}
      >
        <Row className="top-buffer center-row">
          <ControlLabel>Company Name</ControlLabel>
          <FormControl
            name="user[employer_profile_attributes][company_name]"
            onChange={props.onTextFieldChange}
            type="text"
            value={props.companyName}
          />
        </Row>
        <Row>
          <HelpBlock>{props.errors.get('companyName', '')}</HelpBlock>
        </Row>
      </FormGroup>
    </>
  );
};

EmployerProfileDeprecatedForm.propTypes = {
  companyName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  fullName: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  onPasswordScoreChange: PropTypes.func.isRequired,
  onTextFieldChange: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

export default EmployerProfileDeprecatedForm;

import React from 'react';
import {
  Banner as BaseUiBanner,
  BannerProps as BaseUiBannerProps,
  HIERARCHY,
  KIND,
} from 'baseui/banner';

type Props = Pick<
  BaseUiBannerProps,
  'action' | 'artwork' | 'children' | 'hierarchy' | 'kind' | 'title'
> & {
  artworkAlignment?: 'flex-start' | 'center';
};

export const Banner = ({
  action,
  artwork,
  artworkAlignment = 'center',
  children,
  hierarchy,
  kind,
  title,
}: Props) => (
  <BaseUiBanner
    action={action}
    artwork={artwork}
    hierarchy={hierarchy}
    kind={kind}
    title={title}
    overrides={{
      Root: {
        style: ({ $theme }) => ({
          borderRadius: $theme.sizing.unit10,
          ...(hierarchy === HIERARCHY.high &&
            kind === KIND.warning && {
              backgroundColor: $theme.colors.warning,
            }),
          margin: 0,
        }),
      },
      LeadingContent: {
        style: ({ $theme }) => {
          const base = {
            [$theme.mediaQuery.medium]: {
              width: '100%',
            },
            [$theme.mediaQuery.lt.medium]: {
              width: $theme.sizing.scale0,
            },
          };
          if (artworkAlignment === 'flex-start') {
            return {
              ...base,
              alignItems: 'flex-start',
              paddingTop: $theme.sizing.unit16,
            };
          }

          return base;
        },
      },
      TrailingIconButton: {
        style: ({ $theme }) => {
          if (artworkAlignment === 'flex-start') {
            return {
              alignItems: 'flex-start',
              paddingTop: $theme.sizing.unit16,
            };
          }
          return {};
        },
      },
    }}
  >
    {children}
  </BaseUiBanner>
);

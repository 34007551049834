// See https://lucide.dev/guide/packages/lucide-react#one-generic-icon-component
// We import explicitly for 2 reasons.
//   1. Library is tree-shakeable. Better build and runtime performance.
//   2. These icons have been "blessed" by design.
// Please double check with design before adding any icons to this file.
import {
  AlertTriangle,
  Archive,
  ArrowDownUp,
  ArrowDown,
  ArrowDownToLine,
  ArrowRight,
  ArrowLeft,
  ArrowUp,
  ArrowUpRight,
  Binoculars,
  Briefcase,
  Building,
  Calendar,
  CalendarClock,
  Check,
  ChevronsLeft,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsUp,
  CircleCheck,
  CircleDashed,
  CircleHelp,
  CircleX,
  Clock,
  CornerDownLeft,
  CornerUpLeft,
  CornerUpRight,
  Dot,
  Download,
  ExternalLink,
  Ellipsis,
  Eye,
  Flame,
  File,
  Flag,
  GraduationCap,
  Handshake,
  Headphones,
  History,
  Hourglass,
  Heart,
  IdCard,
  Image,
  Info,
  LayoutGrid,
  Lightbulb,
  Loader,
  Loader2,
  Lock,
  LogOut,
  Mail,
  MailOpen,
  MapPin,
  Menu,
  MessageSquare,
  Mic,
  Minus,
  Moon,
  MoreVertical,
  Paperclip,
  Pencil,
  PhoneForwarded,
  Pin,
  Plus,
  Pointer,
  Redo2,
  RefreshCcw,
  Reply,
  Save,
  Scale,
  Search,
  SendHorizontal,
  Settings,
  ShieldCheck,
  Siren,
  Skull,
  Snail,
  SquareUserRound,
  Sun,
  Sunset,
  Star,
  Syringe,
  ThumbsDown,
  ThumbsUp,
  Timer,
  Trash2,
  Users2,
  RotateCcw,
  RotateCw,
  WandSparkles,
  X,
  XCircle,
} from 'lucide-react';

export const lucideIcons = {
  AlertTriangle,
  Archive,
  ArrowDownUp,
  ArrowDown,
  ArrowDownToLine,
  ArrowRight,
  ArrowLeft,
  ArrowUp,
  ArrowUpRight,
  Binoculars,
  Briefcase,
  Building,
  Calendar,
  CalendarClock,
  CircleX,
  Clock,
  Check,
  ChevronsLeft,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsUp,
  CircleCheck,
  CircleDashed,
  CircleHelp,
  CornerDownLeft,
  CornerUpRight,
  CornerUpLeft,
  Dot,
  Download,
  Ellipsis,
  Eye,
  ExternalLink,
  Flame,
  File,
  Flag,
  GraduationCap,
  Handshake,
  Headphones,
  Heart,
  History,
  Hourglass,
  Image,
  Info,
  IdCard,
  LayoutGrid,
  Lightbulb,
  Loader,
  Loader2,
  Lock,
  LogOut,
  Mail,
  MailOpen,
  MapPin,
  Menu,
  MessageSquare,
  Mic,
  Minus,
  Moon,
  MoreVertical,
  Paperclip,
  Pencil,
  PhoneForwarded,
  Pin,
  Plus,
  Pointer,
  Redo2,
  RefreshCcw,
  Reply,
  Save,
  Scale,
  Search,
  SendHorizontal,
  Settings,
  ShieldCheck,
  Siren,
  Skull,
  Snail,
  SquareUserRound,
  Sun,
  Sunset,
  Star,
  Syringe,
  ThumbsDown,
  ThumbsUp,
  Timer,
  Trash2,
  Users2,
  RotateCcw,
  RotateCw,
  WandSparkles,
  X,
  XCircle,
};

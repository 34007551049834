import React, { useState } from 'react';
import {
  ROLE as ModalRole,
  SIZE as ModalSize,
  TalentModal,
} from 'shared/components/ihcl/modal';
import { NurseProfile } from 'talent/types';

import { useBreakpointContext } from 'shared/providers/BreakpointProvider';
import { trackClick } from 'shared/helpers/tracking';
import { useExperimentAssignment } from 'shared/components/AppProvider';
import AppointmentSummary from './AppointmentSummary';
import NewAppointment from './NewAppointment';
import RescheduleAppointment from './RescheduleAppointment';
import CallUsModal from './CallUsModal';
import ReservedAppointmentSummary from './ReservedAppointmentSummary';
import PickADifferentTime from './PickADifferentTime';
import ConfirmationModal from './ConfirmationModal';
import UpdateContactInfoModal from './UpdateContactInfoModal';
import RecentGradPreCheck from './RecentGradPreCheck';

export const MODAL_PATH = '#introCall';

const AppointmentModal = ({
  isOpen,
  talentProfile,
  isForInterviewRequest = false,
  isNotScreenerConnected = false,
  title = 'Kickstart with a 10 min intro call',
  closeModal,
  trackingMetadata = {},
}: {
  isOpen: boolean;
  talentProfile: NurseProfile;
  isForInterviewRequest?: boolean;
  isNotScreenerConnected?: boolean;
  title?: string;
  closeModal: () => void;
  trackingMetadata?: { [key: string]: string | boolean | number };
}): JSX.Element => {
  const [isCallUsOpen, setIsCallUsOpen] = useState(false);
  const [isRescheduling, setIsRescheduling] = useState(false);
  const [isPickingDifferentTime, setIsPickingDifferentTime] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isUpdatingContactInfo, setIsUpdatingContactInfo] = useState(false);

  const clearAndCloseModal = (trigger: string) => {
    trackClick(trigger, trackingMetadata);
    setIsRescheduling(false);
    setIsCallUsOpen(false);
    closeModal();
  };

  const handleNoTimesWork = () => {
    trackClick('talent.screening.modal.call_us', trackingMetadata);
    setIsCallUsOpen(true);
  };

  const handleReschedule = () => {
    trackClick('talent.screening.click.reschedule', trackingMetadata);
    setIsRescheduling(true);
  };

  const handlePickDifferentTime = () => {
    trackClick('talent.screening.click.pick_different_time', trackingMetadata);
    setIsPickingDifferentTime(true);
  };

  const handleConfirm = (trigger: string) => {
    trackClick(trigger);
    setIsConfirmed(true);
  };

  const handleUpdateContactInfo = () => {
    trackClick('talent.screening.click.update_contact_info', trackingMetadata);
    setIsUpdatingContactInfo(true);
    setIsConfirmed(false);
  };

  const breakpoint = useBreakpointContext();
  const size = breakpoint === 'small' ? ModalSize.full : ModalSize.default;

  const notConnectableVariant = useExperimentAssignment({
    experimentName: 'web6094_skip_screening_recent_grads',
    actorId: talentProfile.id,
    withTracking: !!talentProfile.recent_grad,
  });

  const showRecentGradPreCheck =
    talentProfile.recent_grad && notConnectableVariant === 'treatment';

  // TODO: Update rendering to make it harder to show multiple screens at once.
  return (
    <>
      <TalentModal
        useTalentDialog={false}
        closeable
        isOpen={isOpen}
        animate
        autoFocus={false}
        size={size}
        role={ModalRole.dialog}
        overrides={{
          Dialog: {
            style: {
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              display: 'flex',
              flexDirection: 'column',
            },
          },
          Close: {
            style: {
              display: 'none',
            },
          },
        }}
      >
        {showRecentGradPreCheck && (
          <RecentGradPreCheck
            talentProfile={talentProfile}
            closeModal={clearAndCloseModal}
          />
        )}
        {talentProfile.screening?.status === 'reserved' &&
          !isPickingDifferentTime &&
          !isUpdatingContactInfo && (
            <ReservedAppointmentSummary
              talentProfile={talentProfile}
              handlePickDifferentTime={handlePickDifferentTime}
              closeModal={clearAndCloseModal}
              confirm={handleConfirm}
            />
          )}
        {talentProfile.screening?.status === 'scheduled' &&
          !isRescheduling &&
          !isUpdatingContactInfo &&
          !isConfirmed && (
            <AppointmentSummary
              screening={talentProfile.screening}
              handleReschedule={handleReschedule}
              closeModal={clearAndCloseModal}
            />
          )}
        {talentProfile.screening?.status === 'scheduled' && isRescheduling && (
          <RescheduleAppointment
            closeModal={clearAndCloseModal}
            noTimesWorkAction={handleNoTimesWork}
            talentProfile={talentProfile}
          />
        )}
        {talentProfile.screening?.status === 'reserved' &&
          isPickingDifferentTime && (
            <PickADifferentTime
              noTimesWorkAction={handleNoTimesWork}
              talentProfile={talentProfile}
              confirm={handleConfirm}
              back={() => setIsPickingDifferentTime(false)}
            />
          )}
        {isConfirmed && (
          <ConfirmationModal
            isOpen
            closeModal={clearAndCloseModal}
            screening={talentProfile.screening}
            handleUpdateContactInfo={handleUpdateContactInfo}
          />
        )}
        {isUpdatingContactInfo && (
          <UpdateContactInfoModal
            closeModal={clearAndCloseModal}
            talentProfile={talentProfile}
          />
        )}
        {!showRecentGradPreCheck && !talentProfile.screening && (
          <NewAppointment
            closeModal={clearAndCloseModal}
            noTimesWorkAction={handleNoTimesWork}
            talentProfile={talentProfile}
            isForInterviewRequest={isForInterviewRequest}
            isNotScreenerConnected={isNotScreenerConnected}
            title={title}
            trackingMetadata={trackingMetadata}
          />
        )}
      </TalentModal>
      <CallUsModal isOpen={isCallUsOpen} onClose={clearAndCloseModal} />
    </>
  );
};

export default AppointmentModal;

import React from 'react';
import { Text } from 'shared/components/ihcl/text';
import { styled, withStyle } from 'shared/components/ihcl/styled';
import { Stack } from 'shared/components/ihcl/patterns';
import testimonialBg from 'images/testimonial-bg.webp';

const Wrapper = styled('div', ({ $theme }) => ({
  position: 'relative',
  marginTop: '24px',
  paddingLeft: $theme.sizing.unit24,
  paddingRight: $theme.sizing.unit24,
  paddingBottom: $theme.sizing.unit24,
  paddingTop: $theme.sizing.unit24,
}));

const ContentWrapper = styled('div', {
  position: 'relative',
});

const AuthorWrapper = styled('div', {});

const Background = withStyle(Stack, ({ $theme }) => ({
  borderRadius: $theme.sizing.unit24,
  background: `radial-gradient(50.01% 50% at 50% 50%, rgba(255, 255, 255, 0.8) 70%, ${$theme.colors.grayBackground} 100%), url(${testimonialBg}) lightgray 50% / cover no-repeat`,
  filter: `blur(${$theme.sizing.unit12})`,
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: '0',
  top: '0',
}));

const OnboardingTestimonial = ({
  attributionLine,
  author,
  quote,
}: OnboardingTestimonialProps): JSX.Element => (
  <Wrapper data-testid="testimonial">
    <Background data-testid="background" />
    <ContentWrapper>
      <Stack $flexDirection="column" $gapSizing="unit16">
        <Text variant="ParagraphSmall" color="grayLabel">
          “{quote}”
        </Text>

        <AuthorWrapper>
          <Text
            variant="LabelXSmall"
            color="grayPlaceholder"
            style={{ lineHeight: '150%' }}
          >
            {attributionLine && (
              <Text
                variant="LabelXSmall"
                color="grayLine"
                style={{ lineHeight: '150%' }}
              >
                {attributionLine}
              </Text>
            )}
            {author}
          </Text>
        </AuthorWrapper>
      </Stack>
    </ContentWrapper>
  </Wrapper>
);

type OnboardingTestimonialProps = {
  attributionLine: string;
  author: React.ReactNode;
  quote: string;
};

export default OnboardingTestimonial;

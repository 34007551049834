import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  employmentTypeOptions as employmentTypeOptionsOriginal,
  shiftPreferenceOptions,
} from 'shared/constants/selectOptions';

import { ButtonSelect } from 'shared/components/ihcl/button_select';
import { Center } from 'shared/components/ihcl/positioning';

import {
  Onboarding,
  OnboardingSubtitle,
  OnboardingTitle,
  OnboardingContext,
} from 'registration/containers/Onboarding';

const employmentTypeOptions = employmentTypeOptionsOriginal.map(
  (option) => ({ value: option.id, label: option.label }) as any
);

// eslint-disable-next-line react/require-default-props
const OnboardingTechRoles = ({ stepNumber }) => {
  const {
    // @ts-ignore
    onboardingData,
    // @ts-ignore
    setOnboardingData,
  } = useContext(OnboardingContext);
  /* eslint-disable @typescript-eslint/naming-convention */
  const { employment_type = [], shift_preferences = [] } = onboardingData;

  const stepKeys = ['employment_type', 'shift_preferences'];

  return (
    <Center>
      <Onboarding
        autoSpacedStepContents={false}
        centerStepContents
        isNextDisabled={
          shift_preferences.length === 0 || employment_type.length === 0
        }
        currentStep={stepNumber}
        stepKeys={stepKeys}
      >
        <OnboardingTitle $reducedFontSize $additionalTopSpacing>
          Let&apos;s get some details
        </OnboardingTitle>
        <OnboardingSubtitle>Please select all that apply</OnboardingSubtitle>
        <OnboardingSubtitle $fontWeight="bold">
          What are your shift preferences?
        </OnboardingSubtitle>
        <ButtonSelect
          multiSelect
          options={shiftPreferenceOptions}
          orientation="horizontal"
          onChange={(val, option, remove) =>
            remove
              ? setOnboardingData({
                  ...onboardingData,
                  shift_preferences: shift_preferences.filter(
                    (shiftOption) => shiftOption.value !== option.value
                  ),
                })
              : setOnboardingData({
                  ...onboardingData,
                  shift_preferences: (shift_preferences || []).some(
                    (shiftOption) => shiftOption.value === option.value
                  )
                    ? shift_preferences
                    : [...(shift_preferences || []), option],
                })
          }
          selectedOptions={shift_preferences}
        />
        <OnboardingSubtitle $fontWeight="bold">
          What are your employment type preferences?
        </OnboardingSubtitle>
        <ButtonSelect
          multiSelect
          options={employmentTypeOptions}
          onChange={(val, option, remove) =>
            remove
              ? setOnboardingData({
                  ...onboardingData,
                  employment_type: employment_type.filter(
                    (shiftOption) => shiftOption.value !== option.value
                  ),
                })
              : setOnboardingData({
                  ...onboardingData,
                  employment_type: (employment_type || []).some(
                    (employmentOption) =>
                      employmentOption.value === option.value
                  )
                    ? shift_preferences
                    : [...(employment_type || []), option],
                })
          }
          selectedOptions={employment_type}
        />
      </Onboarding>
    </Center>
  );
};
OnboardingTechRoles.propTypes = {
  stepNumber: PropTypes.number.isRequired,
};

export default OnboardingTechRoles;

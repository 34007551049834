import { useCallback, useEffect, useRef, useState } from 'react';

export function usePrevious<C>(
  value: C,
  updateValueWhenEqual = false
): C | undefined {
  const ref = useRef<C>();
  useEffect(() => {
    if (ref.current !== value || updateValueWhenEqual) {
      ref.current = value;
    }
  });
  return ref.current;
}

export const useHash = (): [
  string,
  (newHash: typeof window.location.hash) => void,
] => {
  const [hash, setHash] = useState(() => window.location.hash);

  const hashChangeHandler = useCallback(() => {
    setHash(window.location.hash);
  }, []);

  useEffect(() => {
    window.addEventListener('hashchange', hashChangeHandler);
    return () => {
      window.removeEventListener('hashchange', hashChangeHandler);
    };
  }, [hashChangeHandler]);

  const updateHash = useCallback(
    (newHash: typeof window.location.hash) => {
      if (newHash !== hash) window.location.hash = newHash;
    },
    [hash]
  );

  return [hash, updateHash];
};

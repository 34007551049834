import { styled, useStyletron } from 'shared/components/ihcl/styled';
import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import { Text } from 'shared/components/ihcl/text';
import { MoveDownIcon } from 'shared/components/ihcl/icon';
import { Tag } from './tag';

const DefaultSummaryWrapper = styled('div', {});

type DetailsProps = {
  children: React.ReactNode;
  closedSummary: React.ReactNode;
  openSummary: React.ReactNode;
  SummaryWrapper?: React.ComponentType;
};
// eslint-disable-next-line react/require-default-props
export const Details: React.FC<DetailsProps> = ({
  children,
  closedSummary,
  openSummary,
  SummaryWrapper = DefaultSummaryWrapper,
}) => {
  const [css] = useStyletron();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <details onToggle={() => setIsOpen(!isOpen)}>
      <summary className={css({ cursor: 'pointer' })}>
        <SummaryWrapper>{isOpen ? openSummary : closedSummary}</SummaryWrapper>
      </summary>
      {children}
    </details>
  );
};
Details.defaultProps = {
  SummaryWrapper: DefaultSummaryWrapper,
};

export const StickySectionHeader = styled('div', ({ $top, $theme }: any) => ({
  position: 'sticky',
  flex: '1 0 100%',
  top: $top,
  zIndex: 1,
  borderTop: `1px solid ${$theme.colors.gray00}`,
  backgroundColor: $theme.colors.grayBackground,
  width: 'calc(100% + 16px)',
  marginLeft: `-${$theme.sizing.unit8}`,
  paddingTop: $theme.sizing.unit16,
  paddingRight: $theme.sizing.unit16,
  paddingBottom: $theme.sizing.unit16,
  paddingLeft: $theme.sizing.unit16,
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
}));

/*
// This is an attempt at using the $isStuck prop to conditionally apply the shadow
export const StickySectionHeader = styled(
  'div',
  ({ $isStuck, $top, $theme }: any) => ({
    position: 'sticky',
    top: '-1px',
    zIndex: 1,
    background:
      'linear-gradient(180deg, rgba(0,0,0,0) 55%, rgba(249,249,251,1) 55%)',
    width: '100%',
    marginLeft: `-${$theme.sizing.unit8}`,
    marginRight: `-${$theme.sizing.unit8}`,
    marginTop: `-${$top}`,
    paddingTop: cssValueMath($theme.sizing.unit16, '+', $top),
    paddingRight: $theme.sizing.unit16,
    paddingBottom: $theme.sizing.unit16,
    paddingLeft: $theme.sizing.unit16,
    boxShadow: $isStuck ? '0px 4px 4px 0px rgba(0, 0, 0, 0.06)' : null,
  })
);
 */

export const SectionDivider = styled(
  'hr',
  ({ $hidden, $noBottomMargin, $theme }: any) => ({
    height: '8px',
    marginTop: '24px',
    marginBottom: $noBottomMargin ? 0 : '24px',
    backgroundColor: $theme.colors.gray00,
    display: $hidden ? 'none' : 'block',
  })
);

const StickySectionSubtitleWrapper = styled('div', ({ $theme }) => ({
  paddingLeft: $theme.sizing.unit16,
  paddingRight: $theme.sizing.unit24,
}));

export const CenteredText = styled('div', {
  textAlign: 'center',
});

export const FullWidthTextWrapper = styled('div', {
  flex: '0 0 100%',
});
export const StickySectionDivider: FunctionComponent<{
  title: React.ReactNode;
  subtitle: React.ReactNode;
  top?: string;
  // eslint-disable-next-line react/require-default-props
}> = ({ title, subtitle, top = '0' }) => (
  <>
    <StickySectionHeader $top={top}>
      <Text variant="LabelSmall">
        <MoveDownIcon height="16px" width="16px" />
        <strong>{title}</strong>
      </Text>
    </StickySectionHeader>
    <FullWidthTextWrapper>
      <StickySectionSubtitleWrapper>
        <Text variant="ParagraphXSmall" color="bodyCopyGray">
          {subtitle}
        </Text>
      </StickySectionSubtitleWrapper>
    </FullWidthTextWrapper>
  </>
);
StickySectionDivider.defaultProps = {
  top: '0',
};

const TagsWrapper = styled('div', {
  marginLeft: '-5px',
  marginRight: '5px',
});

const TagsLink = styled<'a', any>('a', ({ $theme }: any) => ({
  color: `${$theme.colors.grayLabel} !important`,
  display: 'block',
  marginTop: '5px',
  marginBottom: '56px',
  textDecoration: 'underline',
}));

type TagsProps = {
  tags: Array<String>;
  [key: string]: any;
};

export const Tags: React.FC<TagsProps> = ({ tags, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasMoreTags = tags.length > 1;
  const renderedTags = isOpen ? tags : tags.slice(0, 1);

  return (
    <>
      <TagsWrapper>
        {renderedTags.map((tag: String) => (
          <Tag {...props} key={tag}>
            {tag}
          </Tag>
        ))}
      </TagsWrapper>
      {hasMoreTags && (
        <TagsLink onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? 'Hide' : 'View'} details
        </TagsLink>
      )}
    </>
  );
};

type FullscreenContainerProps = {
  $backgroundColor?: string;
  [key: string]: any;
};

export const FullscreenContainer: React.FC<FullscreenContainerProps> = styled(
  'div',
  ({ $theme, $backgroundColor, $zIndex }: any) => ({
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: $backgroundColor || $theme.colors.grayBackground,
    zIndex: $zIndex || null,
  })
);

FullscreenContainer.defaultProps = {
  $backgroundColor: null,
  $zIndex: null,
};

type StackProps = {
  $alignItems?: 'baseline' | 'center' | 'flex-start' | 'flex-end' | 'stretch';
  $gapSizing?:
    | 'unit24'
    | 'scale100'
    | 'scale200'
    | 'scale300'
    | 'scale400'
    | 'scale500'
    | 'scale600'
    | 'scale700'
    | 'scale800'
    | 'scale900'
    | 'scale1000'
    | 'unit4'
    | 'unit6'
    | 'unit8'
    | 'unit10'
    | 'unit12'
    | 'unit16'
    | 'unit20'
    | 'unit24'
    | 'unit32'
    | 'unit40'
    | 'unit48';
  $flexDirection?: 'column' | 'row';
  $flexWrap?: 'nowrap' | 'wrap';
  $justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between';
};

export const Stack: React.FC<StackProps> = styled(
  'div',
  ({
    $theme,
    $alignItems,
    $flexDirection,
    $flexWrap,
    $gapSizing,
    $justifyContent,
  }: any) => ({
    alignItems: $alignItems,
    display: 'flex',
    flexDirection: $flexDirection,
    flexWrap: $flexWrap,
    gap: $theme.sizing[$gapSizing],
    justifyContent: $justifyContent,
  })
);

Stack.defaultProps = {
  $alignItems: 'stretch',
  $flexDirection: 'column',
  $justifyContent: 'flex-start',
  $flexWrap: 'nowrap',
};

type TextButtonProps = {
  $color?: string;
  $underline?: boolean;
  $size?: string;
  $bold?: boolean;
  $disabled?: boolean;
};
export const TextButton = styled<'button', TextButtonProps>(
  'button',
  ({ $theme, $color, $size, $underline, $disabled, $bold }: any) => {
    const color =
      $color === 'neutral' ? $theme.colors.grayLabel : $theme.colors.primary;
    const textDecoration = $color === 'neutral' ? 'underline' : 'none';
    const fontSize = $size === 'tiny' ? $theme.sizing.scale500 : null;
    const lineHeight = $size === 'tiny' ? $theme.sizing.scale600 : null;
    const paddingVertical = $size === 'tiny' ? $theme.sizing.scale100 : 0;
    const paddingHorizontal = $size === 'tiny' ? $theme.sizing.scale300 : 0;
    const fontWeight = $bold ? '800' : null;

    const styles = {
      border: 'none',
      color,
      textDecoration: $underline ? 'underline' : textDecoration,
      fontSize,
      fontWeight,
      lineHeight,
      backgroundColor: 'transparent',
      verticalAlign: 'top',
      paddingTop: paddingVertical,
      paddingBottom: paddingVertical,
      paddingRight: paddingHorizontal,
      paddingLeft: paddingHorizontal,
    };
    if ($disabled) {
      return {
        ...styles,
        color: $theme.colors.grayLabel,
        textDecoration: 'none',
        cursor: 'not-allowed',
      };
    }
    return styles;
  }
);
TextButton.defaultProps = {
  $bold: false,
  $color: 'default',
  $underline: false,
  $size: 'default',
};

import React, { useState } from 'react';
import { connect } from 'react-redux';

import { useConfirmScreening } from 'api/talent/hooks/screenings';
import { cancelScreening } from 'shared/actions/screeningActions';
import { getTalentProfile } from 'shared/actions/talentProfileActions';

import { toaster } from 'shared/components/ihcl/toast';
import { Button } from 'shared/components/ihcl/button';
import { ModalHeader } from 'shared/components/ihcl/modal';
import { Stack } from 'shared/components/ihcl/patterns';
import { Text } from 'shared/components/ihcl/text';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';

import { NurseProfile } from 'talent/types';

import ReservedTimeWindow from './ReservedTimeWindow';
import Title from './Title';
import WhyNecessary from './WhyNecessaryModal';
import {
  BoldText,
  ExpandingModalBody,
  FixedModalFooter,
  PrimaryCTAWrapper,
} from './styledComponents';

export function ReservedAppointmentSummary({
  talentProfile,
  handlePickDifferentTime,
  cancelScreeningAction,
  getTalentProfileAction,
  closeModal,
  confirm,
}: {
  talentProfile: NurseProfile;
  cancelScreeningAction: Function;
  getTalentProfileAction: (id: number) => {};
  handlePickDifferentTime: () => void;
  closeModal: (eventName: string) => void;
  confirm: (eventName: string) => void;
}) {
  const [isCanceling, setIsCanceling] = useState(false);

  const { screening } = talentProfile;

  const handleCancel = () => {
    setIsCanceling(true);
    cancelScreeningAction(screening.id)
      .then(() => {
        toaster.info(
          <Stack $flexDirection="row" $gapSizing="scale500">
            <LucideIcon name="Check" size="unit24" />
            <div>Great! You will receive an email reminder in few hours</div>
          </Stack>
        );
      })
      .then(() => {
        setIsCanceling(false);
        closeModal('talent.screening.schedule_later');
      });
  };

  const { mutate: confirmScreening, isLoading: isConfirming } =
    useConfirmScreening({
      id: screening.id,
      onSuccess: () => {
        getTalentProfileAction(talentProfile.id);
        confirm('talent.screening.confirm');
      },
    });

  return (
    <>
      <ModalHeader>
        <Title>Your spot is reserved!</Title>
      </ModalHeader>
      <ExpandingModalBody>
        <Stack $gapSizing="unit10" $flexDirection="column">
          <Text variant="ParagraphMedium">
            An Incredible Health team member will call during your reserved time
            window to chat through your job matches - the call itself won&apos;t
            take more than <BoldText>10 minutes*</BoldText>.
          </Text>
          <div>
            <WhyNecessary />
          </div>
        </Stack>
        <ReservedTimeWindow screening={screening} />
        <Button
          size="large"
          color="neutral"
          kind="minimal"
          isLoading={isCanceling}
          disabled={isConfirming}
          onClick={() => handleCancel()}
        >
          <u>Remind me to schedule later</u>
        </Button>
      </ExpandingModalBody>
      <FixedModalFooter>
        <Button
          size="default"
          color="alternate"
          kind="tertiary"
          onClick={() => handlePickDifferentTime()}
          disabled={isCanceling}
        >
          Pick different time
        </Button>
        <PrimaryCTAWrapper>
          <Button
            onClick={() => confirmScreening()}
            size="default"
            color="alternate"
            kind="primary"
            disabled={isConfirming}
            fullWidth
          >
            Got it!
          </Button>
        </PrimaryCTAWrapper>
      </FixedModalFooter>
    </>
  );
}

export default connect(null, {
  cancelScreeningAction: cancelScreening,
  getTalentProfileAction: getTalentProfile,
})(ReservedAppointmentSummary);

import { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { v4 as uuid4 } from 'uuid';
import { compact, once, noop, memoize } from 'lodash';
import { useInViewEffect } from 'react-hook-inview';
import snakeCase from 'just-snake-case';
import stringify from 'fast-json-stable-stringify';
import { onLCP, onTTFB } from 'web-vitals';

import Sentry from 'shared/helpers/sentry';

const snakeCaseObjectKeys = (obj) =>
  Object.entries(obj).reduce(
    (renamedObj, [key, value]) => ({ ...renamedObj, [snakeCase(key)]: value }),
    {}
  );

export const MixpanelEvent = Object.freeze({
  click: 'Click',
  componentViewed: 'Component Viewed',
  mentalHealthActivityCreated: 'Mental Health Activity Created',
  openInNativePromptViewed: 'Open in Native Prompt Viewed',
  pageView: 'PageView',
  permissionRequestDispatch: 'Permission Request Dispatch',
  profileUpdate: 'ProfileUpdate',
  query: 'Query',
  reactivationModalViewed: 'Reactivation Modal Viewed',
  reactivationModalClosed: 'Reactivation Modal Closed',
  referralCodeCopy: 'ReferralCodeCopy',
  reviewRequestDispatch: 'Review Request Dispatch',
  screeningCallScheduled: 'Screening Call Scheduled',
  screeningPromptViewed: 'Screening Prompt Viewed',
  signup: 'Signup',
});

export function trackClick(name, properties = {}, cb = noop) {
  const cbOnce = once(cb);

  setTimeout(() => cbOnce(), 500);

  mixpanel.track(
    MixpanelEvent.click,
    {
      ...properties,
      Name: name,
    },
    cbOnce
  );
}

export function trackEvent(event, properties = {}) {
  mixpanel.track(event, properties);
}

export const trackEventOnce = memoize(trackEvent, (event, properties = {}) => {
  const key = stringify({ event, properties });
  return key;
});

export function trackComponentView(name, properties = {}) {
  mixpanel.track(MixpanelEvent.componentViewed, { ...properties, Name: name });
}

function trackAdwordsConversion(conversionId, conversionOpts = {}) {
  const { googleTagId } = window.IH;

  if (googleTagId) {
    const gtag = function gtag() {
      // Google tag manager requires `arguments` an array of params won't work.
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };
    gtag('js', new Date());
    gtag('config', googleTagId);
    gtag('event', 'conversion', { send_to: conversionId, ...conversionOpts });
  }
}

function trackGAEvent({ category, action }) {
  if (window.IH && window.IH.googleAnalyticsKey) {
    ReactGA.event({ category, action });
  }
}

function trackLinkedInConversion() {
  const { linkedInConversionId } = window.IH;
  if (linkedInConversionId) {
    const img = document.createElement('img');
    img.height = '1';
    img.width = '1';
    img.style = 'display:none;';
    img.alt = '';
    img.src =
      'https://dc.ads.linkedin.com/collect/' +
      `?pid=59827&conversionId=${linkedInConversionId}&fmt=gif`;
    document.body.appendChild(img);
  }
}

export function trackAdQuick() {
  const { adquickEnabled } = window.IH;
  if (adquickEnabled) {
    const ordnumber = Math.random() * 10000000000000;
    const scheme =
      document.location.protocol === 'https:' ? 'https://' : 'http://';
    const sscUrl =
      `${scheme}trkn.us/pixel/conv/ppt=19390;g=` +
      `signup_confirmation;gid=45193;ord=${ordnumber}v=120`;
    const x = document.createElement('IMG');
    x.setAttribute('src', sscUrl);
    x.setAttribute('width', '1');
    x.setAttribute('height', '1');
    document.body.appendChild(x);
  }
}

function getMatchingData(tp) {
  const matchingData = {
    em: tp.email,
    fn: tp.first_name && tp.first_name.toLowerCase().replace(/[^a-z]/g, ''),
    ln: tp.last_name && tp.last_name.toLowerCase().replace(/[^a-z]/g, ''),
    ph: tp.phone && tp.phone.tel_uri && tp.phone.tel_uri.replace('+', ''),
    ct: tp.current_city && tp.current_city.toLowerCase().replace(/[^a-z]/g, ''),
    st: tp.current_state && tp.current_state.toLowerCase(),
    zp: tp.current_zip_code,
  };

  return Object.fromEntries(Object.entries(matchingData).filter((kv) => kv[1]));
}

function trackFBEvent(eventName, tp, params = {}, dedupParams = null) {
  const pixelId = window.IH?.fbPixelID;

  if (!pixelId) {
    return;
  }

  fbq('init', pixelId, getMatchingData(tp));

  (async () => {
    let matches = 0;
    try {
      const response = await fetch('/talent_app_matches.json');
      const data = await response.json();
      matches = data.matches?.length || 0;
    } catch (error) {
      Sentry.captureException(error);
    }

    const paramsWithIntent = {
      ...params,
      intent: tp.signup_intent,
      job_matches: matches,
      autofailed: tp.screen_status === 'failed' ? 'Yes' : 'No',
    };

    try {
      fbq('track', eventName, paramsWithIntent, dedupParams);
    } catch (error) {
      if (error instanceof TypeError && error.message === 'Load failed') {
        Sentry.addBreadcrumb({
          category: 'tracking',
          message: `trackFBEvent: TypeError: Load failed (${eventName})`,
          level: 'warning',
        });
      } else {
        Sentry.captureException(error);
      }
    }
  })();
}

export function trackProfileComplete(tp) {
  const requiredParams = { currency: 'USD', value: 0 };
  const dedupParams = { eventID: tp.id.toString() };
  trackFBEvent('Purchase', tp, requiredParams, dedupParams);
}

export function trackFBLead(tp) {
  const baseConversionValue = window.IH?.fbBaseConversionValue || 0;

  const valueOpts = {
    value: tp.conversion_multiplier * baseConversionValue,
    currency: 'USD',
  };
  trackFBEvent('Lead', tp, valueOpts);

  trackEvent('debug.conversion', {
    type: 'FB lead',
    value: valueOpts.value,
    base_conversion_value: baseConversionValue,
    multiplier: tp.conversion_multiplier,
    talent_profile_id: tp.id,
    profile_type: tp.type,
  });
}

export function trackNonHiringSignUpCompletion(tp) {
  trackFBEvent('CompleteRegistration', tp);
  trackAdQuick();
  if (tp.signup_intent === 'salary') {
    const { googleNonHiringSalaryOnboardViewConversionId } = window.IH;
    trackAdwordsConversion(googleNonHiringSalaryOnboardViewConversionId);
  } else if (tp.signup_intent === 'advice') {
    const { googleNonHiringAdviceOnboardViewConversionId } = window.IH;
    trackAdwordsConversion(googleNonHiringAdviceOnboardViewConversionId);
  } else if (tp.signup_intent === 'resume') {
    const { googleNonHiringResumeOnboardViewConversionId } = window.IH;
    trackAdwordsConversion(googleNonHiringResumeOnboardViewConversionId);
  }
}

function trackSnapSignUp(tp) {
  if (!window.IH.snapPixelID) {
    return;
  }
  snaptr('init', window.IH.snapPixelID, {
    user_email: tp.email,
    user_phone_number: tp.phone && tp.phone.tel_uri.replace('+', ''),
  });
  snaptr('track', 'SIGN_UP');
}

function trackNextdoorConversion(tp) {
  if (!window.IH.nextdoorPixelID) {
    return;
  }
  ndp('init', window.IH.nextdoorPixelID, { user_email: tp.email });
  ndp('track', 'CONVERSION');
}

export function trackHiringActivated(tp) {
  trackFBLead(tp);
  trackSnapSignUp(tp);
  trackNextdoorConversion(tp);
  trackAdQuick();
}

export function trackViewContent(tp) {
  const param = {};
  if (tp.job_search_status) {
    param.job_search_status = tp.job_search_status;
  }
  trackFBEvent('ViewContent', tp, param);
}

export function trackHiringSignUp(tp) {
  mixpanel.track(MixpanelEvent.signup, {
    Method: 'email',
    'Onboard Variant': tp.onboard_variant,
  });

  trackHiringActivated(tp);

  const {
    googleEmailCompleteConversionId,
    googleEmailCompleteNotFailedConversionId,
    googleBaseConversionValues,
    googleTechEmailCompleteConversionId,
  } = window.IH;

  const {
    emailComplete: signupValue = 0,
    emailCompleteNotFailed: nonAutofailSignupValue = 0,
  } = googleBaseConversionValues || {};

  const conversionOpts = {
    value: tp.conversion_multiplier * signupValue,
    currency: 'USD',
  };
  const googleEmailConversionId =
    tp.type === 'TechProfile'
      ? googleTechEmailCompleteConversionId
      : googleEmailCompleteConversionId;
  trackAdwordsConversion(googleEmailConversionId, conversionOpts);
  trackEvent('debug.conversion', {
    type: 'Google hiring signup',
    value: conversionOpts.value,
    base_conversion_value: signupValue,
    multiplier: tp.conversion_multiplier,
    talent_profile_id: tp.id,
    talent_type: tp.type,
  });

  if (tp.screen_status !== 'failed') {
    const nonAutofailConversionOpts = {
      value: tp.conversion_multiplier * nonAutofailSignupValue,
      currency: 'USD',
    };
    trackAdwordsConversion(
      googleEmailCompleteNotFailedConversionId,
      nonAutofailConversionOpts
    );
    trackEvent('debug.conversion', {
      type: 'Google non-autofail hiring signup',
      value: nonAutofailConversionOpts.value,
      base_conversion_value: nonAutofailSignupValue,
      multiplier: tp.conversion_multiplier,
      talent_profile_id: tp.id,
    });
  }

  trackGAEvent({ category: 'Onboarding', action: 'Entered Email' });
  trackLinkedInConversion();

  window.uetq = window.uetq || [];
  window.uetq.push('event', 'lead', {
    event_category: 'lead',
    event_label: 'lead',
    event_value: '0',
  });
}

export function registerImpressionId() {
  const impressionId = uuid4();
  mixpanel.register({ 'Impression ID': impressionId });
}

export function registerSignupIntent(intent) {
  if (intent) {
    mixpanel.register_once({
      signup_intent: intent,
    });
  }
}

export function getPageName(pathName) {
  const pathNameParts = compact(pathName.toLowerCase().split('/'));
  return pathNameParts
    .map((part) => (Number.isNaN(Number(part)) ? part : 'ID'))
    .join('.');
}

export function trackTalentPageView(pageName, properties = {}) {
  mixpanel.track(MixpanelEvent.pageView, {
    ...properties,
    'Page Name': pageName,
  });
}

export function trackTalentEditProfile() {
  mixpanel.track(MixpanelEvent.profileUpdate);
}

export function trackTalentReferralCopy() {
  mixpanel.track(MixpanelEvent.referralCodeCopy);
}

export function trackNonHiringOnboardPageView() {
  fbq('track', 'PageView');

  const { googleNonHiringOnboardViewConversionId } = window.IH;
  trackAdwordsConversion(googleNonHiringOnboardViewConversionId);
}

export function genTrackAppDownloadClick(properties = {}) {
  return (event) => {
    const { href } = event.currentTarget;

    trackClick('talent.app.download', {
      Destination: new URL(href).hostname,
      ...properties,
    });
  };
}

const VIEW_CALLBACK_UNSET = 0;
const VIEW_CALLBACK_COMPLETED = -1;

export function useViewTracker(
  viewName,
  properties = {},
  viewVisibilityThreshold = 1, // view target becomes 100% visible
  delay = 3000 // milliseconds
) {
  const [timeoutID, setTimeoutID] = useState(VIEW_CALLBACK_UNSET);
  const { containerRef, ingestData = false, ...viewProperties } = properties;

  const dataIngestionProperties = {};

  if (!ingestData) {
    dataIngestionProperties['Data Ingest'] = false;
  }

  useEffect(
    () => () => {
      if (timeoutID > 0) {
        clearTimeout(timeoutID);
      }
    },
    [timeoutID]
  );

  const ref = useInViewEffect(
    ([entry], observer) => {
      switch (timeoutID) {
        case VIEW_CALLBACK_UNSET:
          if (entry.intersectionRatio >= viewVisibilityThreshold) {
            setTimeoutID(
              setTimeout(() => {
                setTimeoutID(VIEW_CALLBACK_COMPLETED);

                if (viewName) {
                  trackEventOnce(`View ${viewName}`, {
                    ...viewProperties,
                    ...dataIngestionProperties,
                  });
                }
              }, delay)
            );
          }
          break;

        case VIEW_CALLBACK_COMPLETED:
          observer.unobserve(entry.target);
          break;

        default: // view callback enqueued
          if (!entry.isIntersecting) {
            clearTimeout(timeoutID);
            observer.unobserve(entry.target);
          }
      }
    },
    {
      root: containerRef?.current || null,
      threshold: [
        0, // run view callback once 1 pixel of the target appears on-screen
        viewVisibilityThreshold,
      ],
    },
    [timeoutID]
  );

  return ref;
}

export function useDisplayTracker(
  displayName,
  properties = {},
  displayVisibilityThreshold = 1 // content becomes 100% visible
) {
  const { containerRef, ingestData, ...viewProperties } = properties;
  const dataIngestionProperties = {};

  if (!ingestData) {
    dataIngestionProperties['Data Ingest'] = false;
  }
  const ref = useInViewEffect(
    ([entry], observer) => {
      if (entry.intersectionRatio >= displayVisibilityThreshold) {
        if (displayName) {
          trackEventOnce(`Display ${displayName}`, {
            ...viewProperties,
            ...dataIngestionProperties,
          });
        }

        observer.unobserve(entry.target);
      }
    },
    {
      root: containerRef?.current || null,
      threshold: [
        0, // run callback once 1 pixel of the target appears on-screen
        displayVisibilityThreshold,
      ],
    },
    []
  );

  return ref;
}

function getParameterByName(name, url = window.location.href) {
  const urlObj = new URL(url);
  return urlObj.searchParams.get(name);
}

export function decorateUTMParams(url) {
  const urlObj = new URL(url);
  const params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term'];
  params.forEach((param) => {
    const utmValue = getParameterByName(param) || null;
    if (utmValue) {
      urlObj.searchParams.set(param, utmValue);
    }
  });
  return urlObj.toString();
}

// more info on usage and best practices here:
// https://www.notion.so/incrediblehealth/Experiments-721b6079cbf64089bf7d808407afd910?pvs=4
export function trackExperimentAssignment(
  experimentName,
  variantName,
  additionalEventProperties = {}
) {
  mixpanel.track('Experiment Assignment', {
    ...additionalEventProperties,
    experiment_name: experimentName,
    variant_name: variantName,
    enrolled: !!variantName,
  });
}

export function trackAppLoad(appName) {
  const doTrackAppLoad = () => {
    let trackedPerformanceMetrics = {};
    if (typeof performance.getEntriesByType === 'function') {
      const paintMetrics = performance.getEntriesByType('paint');
      if (paintMetrics !== undefined && paintMetrics.length > 0) {
        paintMetrics.forEach((paintMetric) => {
          trackedPerformanceMetrics[paintMetric.name] = parseInt(
            paintMetric.startTime,
            10
          );
        });
      }
      const navigationMetricsArr = performance.getEntriesByType('navigation');
      const trackedNavigationMetrics = [
        'domComplete',
        'domInteractive',
        'transferSize',
      ];
      if (
        navigationMetricsArr !== undefined &&
        navigationMetricsArr.length > 0
      ) {
        navigationMetricsArr.forEach((navigationMetrics) => {
          trackedNavigationMetrics.forEach((metric) => {
            trackedPerformanceMetrics[metric] = parseInt(
              navigationMetrics[metric],
              10
            );
          });
        });
      }
    }
    if (typeof performance.getEntries === 'function') {
      const entrySizeMetrics = performance.getEntries().reduce(
        (loadSize, entry) => {
          const transferSize = entry.transferSize
            ? loadSize.transferSize + entry.transferSize
            : loadSize.transferSize;
          const encodedBodySize = entry.encodedBodySize
            ? loadSize.encodedBodySize + entry.encodedBodySize
            : loadSize.encodedBodySize;
          const decodedBodySize = entry.decodedBodySize
            ? loadSize.decodedBodySize + entry.decodedBodySize
            : loadSize.decodedBodySize;

          return {
            transferSize,
            encodedBodySize,
            decodedBodySize,
          };
        },
        {
          transferSize: 0,
          encodedBodySize: 0,
          decodedBodySize: 0,
        }
      );
      trackedPerformanceMetrics = snakeCaseObjectKeys({
        ...trackedPerformanceMetrics,
        ...entrySizeMetrics,
      });
    }
    trackEvent(`App Load - ${appName}`, trackedPerformanceMetrics);
    onLCP(({ name, navigationType, rating, value }) => {
      trackEvent(`LCP - ${appName}`, {
        name,
        navigation_type: navigationType,
        rating,
        value,
      });
    });
    onTTFB(({ name, navigationType, rating, value }) => {
      trackEvent(`TTFB - ${appName}`, {
        name,
        navigation_type: navigationType,
        rating,
        value,
      });
    });
  };
  if (Object.prototype.hasOwnProperty.call(window, 'performance')) {
    if (document.readyState !== 'complete') {
      window.addEventListener('load', doTrackAppLoad);
    } else {
      doTrackAppLoad();
    }
  }
}

import { withStyle, Theme } from 'shared/components/ihcl/styled';
import {
  Button as BaseButton,
  StyledBaseButton as BaseStyledBaseButton,
  ButtonOverrides,
  SIZE,
} from 'baseui/button';
import React from 'react';

function getSpacingStyles({ sizing, $padding, $size }) {
  const marginStyles = {
    marginTop: '0',
    marginRight: '0',
    marginBottom: '0',
    marginLeft: '0',
  };
  let horizontalPadding = sizing.scale800;
  if ($padding === 'minimal') {
    horizontalPadding = sizing.scale600;
  }
  if ($padding === 'tiny') {
    horizontalPadding = sizing.scale300;
  }
  let verticalPadding = 0;
  switch ($size) {
    case 'icon':
      return {
        ...marginStyles,
        paddingBottom: '2px',
        paddingLeft: '2px',
        paddingRight: '2px',
        paddingTop: '2px',
        height: '22px',
        width: '22px',
      };
    case SIZE.mini:
      verticalPadding = sizing.scale200;
      return {
        ...marginStyles,
        paddingTop: verticalPadding,
        paddingBottom: verticalPadding,
        paddingLeft: horizontalPadding,
        paddingRight: horizontalPadding,
      };
    case SIZE.compact:
      verticalPadding = sizing.scale400;
      return {
        ...marginStyles,
        paddingTop: verticalPadding,
        paddingBottom: verticalPadding,
        paddingLeft: horizontalPadding,
        paddingRight: horizontalPadding,
      };
    case SIZE.large:
      verticalPadding = sizing.scale600;
      return {
        ...marginStyles,
        paddingTop: verticalPadding,
        paddingBottom: verticalPadding,
        paddingLeft: horizontalPadding,
        paddingRight: horizontalPadding,
      };
    default:
      verticalPadding = sizing.scale550;
      return {
        ...marginStyles,
        paddingTop: verticalPadding,
        paddingBottom: verticalPadding,
        paddingLeft: horizontalPadding,
        paddingRight: horizontalPadding,
      };
  }
}

export const StyledBaseButton = withStyle(
  BaseStyledBaseButton,
  ({
    $theme: { buttonColors, sizing, components },
    $kind,
    $color,
    $flexDirection = undefined,
    $fullWidth,
    $padding,
    $squareCorners,
    $size,
    $textColors = {},
  }: {
    $theme: Theme;
    $kind?: string;
    $color?: string;
    $flexDirection?: string;
    $fullWidth?: boolean;
    $padding?: string;
    $squareCorners?: boolean;
    $size?: string;
    $textColors?: {
      color?: string;
      disabled?: string;
      disabledHover?: string;
      hover?: string;
      activeFocus?: string;
      focus?: string;
    };
  }) => {
    const getBoxShadow = (color, borderWidth) =>
      `inset 0 0 0 ${borderWidth} ${color}`;
    let sharedStyles: any = getSpacingStyles({ sizing, $padding, $size });

    sharedStyles.fontWeight = 500;

    if (components?.button) {
      sharedStyles = {
        ...sharedStyles,
        ...components.button,
      };
    }
    if ($fullWidth) {
      sharedStyles.width = '100%';
    }
    if ($squareCorners) {
      sharedStyles.borderTopRightRadius = 0;
      sharedStyles.borderBottomRightRadius = 0;
      sharedStyles.borderBottomLeftRadius = 0;
      sharedStyles.borderTopLeftRadius = 0;
    }
    const suffix = $color
      ? $color.charAt(0).toUpperCase() + $color.slice(1)
      : '';
    const styles = buttonColors
      ? buttonColors[$kind + suffix] || buttonColors[$kind] || {}
      : {};
    return {
      ...sharedStyles,
      backgroundColor: styles.backgroundColor,
      color: $textColors.color || styles.textColor,
      boxShadow: getBoxShadow(styles.outlineColor, styles.outlineWidth),
      flexDirection: $flexDirection,
      ':disabled': {
        color:
          $textColors.disabled || $textColors.color || styles.disabledTextColor,
        backgroundColor: styles.disabledBackgroundColor,
        boxShadow: getBoxShadow(
          styles.disabledOutlineColor,
          styles.outlineWidth
        ),
      },
      ':disabled:hover': {
        color:
          $textColors.disabledHover ||
          $textColors.color ||
          styles.disabledTextColor,
        backgroundColor: styles.disabledBackgroundColor,
        boxShadow: getBoxShadow(
          styles.disabledOutlineColor,
          styles.outlineWidth
        ),
      },
      ':hover': {
        color: $textColors.hover || $textColors.color || styles.hoverTextColor,
        backgroundColor: styles.hoverBackgroundColor,
        boxShadow: getBoxShadow(styles.hoverOutlineColor, styles.outlineWidth),
      },
      ':active:focus': {
        color:
          $textColors.activeFocus || $textColors.color || styles.activeColor,
        backgroundColor: styles.activeBackgroundColor,
        boxShadow: getBoxShadow(styles.activeOutlineColor, styles.outlineWidth),
      },
      ':focus': {
        color: $textColors.focus || $textColors.color || styles.textColor,
        boxShadow: getBoxShadow(
          styles.focusOutlineColor,
          styles.focusOutlineWidth
        ),
      },
    };
  }
);

type ButtonColor =
  | 'alternate'
  | 'alternateDark'
  | 'neutral'
  | 'negative'
  | null;
export type ButtonPropsType = {
  color?: ButtonColor;
  endEnhancer?: any;
  forwardedRef?: Function | { current: any };
  fullWidth?: boolean;
  isLoading?: boolean;
  overrides?: ButtonOverrides;
  padding?: 'default' | 'minimal' | 'tiny';
  squareCorners?: boolean;
  startEnhancer?: any;
  kind?: 'primary' | 'secondary' | 'tertiary' | 'minimal' | 'outline' | 'icon';
  textColors?: {
    color?: string;
    disabled?: string;
    disabledHover?: string;
    hover?: string;
    activeFocus?: string;
    focus?: string;
  };
  key?: any;
} & Omit<React.ComponentProps<typeof BaseButton>, 'kind' | 'key'>;

// TODO: Remove this when forwardedref is fixed
//      in baseui (maybe upgrade will fix it)
const TypelessBaseButton = BaseButton as any;

export const Button = ({
  startEnhancer = null,
  endEnhancer = null,
  forwardedRef = null,
  fullWidth = false,
  isLoading = false,
  padding = 'default',
  squareCorners = false,
  textColors = {},
  ...props
}: ButtonPropsType) => {
  const { BaseButton: BaseButtonOverrides, ...overrides } =
    props?.overrides || {};
  return (
    <TypelessBaseButton
      endEnhancer={endEnhancer}
      startEnhancer={startEnhancer}
      forwardedRef={forwardedRef}
      isLoading={isLoading}
      {...props}
      overrides={{
        BaseButton: {
          props: {
            ...props,
            $color: props.color,
            $fullWidth: fullWidth,
            $padding: padding,
            $squareCorners: squareCorners,
            $textColors: textColors,
          },
          component: StyledBaseButton,
          ...BaseButtonOverrides,
        },
        ...overrides,
      }}
    />
  );
};

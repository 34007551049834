import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { styled } from 'shared/components/ihcl/styled';

import { Checkbox } from 'shared/components/ihcl/checkbox';
import { Input } from 'shared/components/ihcl/input';
import { Center } from 'shared/components/ihcl/positioning';
import { MonthPicker } from 'shared/components/ihcl/month_picker_split_fields';
import { Select } from 'shared/components/ihcl/select';
import { degreeTypeOptions } from 'shared/constants/selectOptions';

import {
  Onboarding,
  OnboardingSubtitle,
  OnboardingTitle,
} from 'registration/containers/Onboarding';
import { OnboardingContext } from '../../containers/Onboarding';

const InputWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: $theme.sizing.unit16,
}));

// eslint-disable-next-line react/require-default-props
const OnboardingResume = ({
  nextAction = null,
  onboardingWrapper = null,
  onboardingWrapperProps = {},
  previousAction = null,
  stepNumber,
}) => {
  const [hasAnotherDegree, setHasAnotherDegree] = useState(false);
  const {
    // @ts-ignore
    onboardingData,
    // @ts-ignore
    setOnboardingData,
  } = useContext(OnboardingContext);
  const [educationExperience1, setEducationExperience1] = useState(
    onboardingData?.talentEducationExperiences?.[0] || {}
  );
  const [educationExperience2, setEducationExperience2] = useState(
    onboardingData?.talentEducationExperiences?.[1] || {}
  );
  useEffect(() => {
    const talentEducationExperiences = [educationExperience1];
    if (hasAnotherDegree) {
      talentEducationExperiences.push(educationExperience2);
    }
    if (
      JSON.stringify(talentEducationExperiences) !==
      JSON.stringify(onboardingData.talentEducationExperiences)
    ) {
      setOnboardingData({
        ...onboardingData,
        talentEducationExperiences,
      });
    }
  }, [
    hasAnotherDegree,
    educationExperience1,
    educationExperience2,
    onboardingData,
    setOnboardingData,
  ]);

  if (!onboardingData || Object.keys(onboardingData).length < 1) {
    return null;
  }

  return (
    <Onboarding
      currentStep={stepNumber}
      isNextDisabled={false}
      nextAction={nextAction}
      previousAction={previousAction}
      stepKeys={['talentEducationExperiences']}
      onboardingWrapper={onboardingWrapper}
      onboardingWrapperProps={onboardingWrapperProps}
      validateBeforeSubmit={() => true}
    >
      <Center>
        <OnboardingTitle $reducedFontSize $additionalTopSpacing>
          Show off your credentials!
        </OnboardingTitle>
        <OnboardingSubtitle>
          Where did you complete your certification program?
        </OnboardingSubtitle>
        <InputWrapper>
          <Select
            instanceId="certificationType"
            placeholder="Select degree type"
            value={degreeTypeOptions.filter(
              (option) => option.value === educationExperience1?.degree_type
            )}
            valueKey="value"
            options={degreeTypeOptions}
            clearable={false}
            onChange={(evt) => {
              const inputValue = evt.value.length > 0 ? evt.value[0].value : '';
              setEducationExperience1({
                ...educationExperience1,
                degree_type: inputValue,
              });
            }}
          />
          <Input
            value={educationExperience1?.institution_name}
            onChange={(e) =>
              setEducationExperience1({
                ...educationExperience1,
                institution_name: e.target.value,
              })
            }
            label="Institution attended"
          />
          <Input
            value={educationExperience1?.major}
            onChange={(e) =>
              setEducationExperience1({
                ...educationExperience1,
                major: e.target.value,
              })
            }
            label="Field of study"
          />
          <MonthPicker
            instanceId="graduationDate"
            yearLabel="Graduation year"
            yearValue={educationExperience1?.year}
            monthValue={educationExperience1?.month}
            onChange={({ month, year }) => {
              setEducationExperience1({
                ...educationExperience1,
                graduation_month: month,
                graduation_year: year,
              });
            }}
          />
          <Checkbox
            checked={hasAnotherDegree}
            onChange={(e) => setHasAnotherDegree(e.target.checked)}
          >
            I have another degree
          </Checkbox>
        </InputWrapper>
        {hasAnotherDegree && (
          <>
            <OnboardingSubtitle>
              Where did you get your degree?
            </OnboardingSubtitle>
            <InputWrapper>
              <Select
                instanceId="degreeType"
                value={degreeTypeOptions.filter(
                  (levelOption) =>
                    levelOption.value === educationExperience2?.degree_type
                )}
                valueKey="value"
                onChange={(evt) => {
                  const inputValue =
                    evt.value.length > 0 ? evt.value[0].value : '';
                  setEducationExperience2({
                    ...educationExperience2,
                    degree_type: inputValue,
                  });
                }}
                options={degreeTypeOptions}
                clearable={false}
                placeholder="Please select a level"
              />
              <Input
                value={educationExperience2?.institution_name}
                onChange={(e) =>
                  setEducationExperience2({
                    ...educationExperience2,
                    institution_name: e.target.value,
                  })
                }
                label="Educational institution attended"
              />
              <Input
                value={educationExperience2?.major}
                onChange={(e) =>
                  setEducationExperience2({
                    ...educationExperience2,
                    major: e.target.value,
                  })
                }
                label="Field of study/major"
              />
              <MonthPicker
                yearLabel="Graduation year"
                yearValue={educationExperience2?.year}
                monthValue={educationExperience2?.month}
                onChange={({ month, year }) => {
                  setEducationExperience2({
                    ...educationExperience2,
                    graduation_month: month,
                    graduation_year: year,
                  });
                }}
              />
            </InputWrapper>
          </>
        )}
      </Center>
    </Onboarding>
  );
};
OnboardingResume.propTypes = {
  nextAction: PropTypes.string,
  onboardingWrapper: PropTypes.func,
  onboardingWrapperProps: PropTypes.object,
  previousAction: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
};

export default OnboardingResume;

import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { styled } from 'shared/components/ihcl/styled';

import { Checkbox } from 'shared/components/ihcl/checkbox';
import { Input } from 'shared/components/ihcl/input';
import { Center } from 'shared/components/ihcl/positioning';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';

import {
  Onboarding,
  OnboardingSubtitle,
  OnboardingTitle,
} from 'registration/containers/Onboarding';
import { Button } from 'shared/components/ihcl/button';
import { PlusIcon } from 'shared/components/ihcl/icon';
import { Text } from 'shared/components/ihcl/text';
import { OnboardingContext } from '../../containers/Onboarding';

const InputWrapper = styled('div', ({ $theme }) => ({
  marginBottom: $theme.sizing.unit24,
}));

const OnboardingTechCertifications = ({
  nextAction = null,
  onboardingWrapper = null,
  onboardingWrapperProps = {},
  previousAction = null,
  stepNumber,
}) => {
  const {
    // @ts-ignore
    onboardingData,
    // @ts-ignore
    onboardingOptions = {},
    // @ts-ignore
    setOnboardingData,
  } = useContext(OnboardingContext);

  // @ts-ignore
  const { tech_certifications: unfilteredTechCertifications } =
    onboardingOptions;
  const { techRoleOptions } = onboardingData;
  const selectedTechRoleCategories = new Set(
    techRoleOptions?.map((option) => option.category)
  );
  const onlyOtherTechRoleSelected =
    selectedTechRoleCategories.size === 1 &&
    selectedTechRoleCategories.has('Other');
  const selectedTechRoleIds = techRoleOptions?.map((option) => option.value);
  const [pendingSelectedOptionsSet, setPendingSelectedOptionsSet] = useState(
    new Set()
  );
  const [pendingCustomCertifications, setPendingCustomCertifications] =
    useState([]);
  const [filterCertificatesValue, setFilterCertificatesValue] = useState('');
  useEffect(() => {
    setOnboardingData({
      ...onboardingData,
      techCertifications: Array.from(pendingSelectedOptionsSet),
    });
  }, [pendingSelectedOptionsSet, setOnboardingData]);
  useEffect(() => {
    const techCustomCertifications = (pendingCustomCertifications || [])
      .map((certification) => {
        if (certification.enabled && certification.description) {
          return { description: certification.description };
        }
        return null;
      })
      .filter(Boolean);
    setOnboardingData({
      ...onboardingData,
      techCustomCertifications,
    });
  }, [pendingCustomCertifications, setOnboardingData]);

  if (!onboardingData || Object.keys(onboardingData).length < 1) {
    return null;
  }

  function getTechCertifications() {
    if (onlyOtherTechRoleSelected) {
      return unfilteredTechCertifications.filter(
        (cert) => cert.certification_type === 'general'
      );
    }

    if (selectedTechRoleIds && selectedTechRoleIds.length > 0) {
      return unfilteredTechCertifications.filter((cert) =>
        cert.tech_job_description_ids.some((certId) =>
          selectedTechRoleIds.includes(certId)
        )
      );
    }

    return unfilteredTechCertifications;
  }

  const techCertifications = getTechCertifications();

  return (
    <Onboarding
      currentStep={stepNumber}
      isNextDisabled={
        !onboardingData?.techCertifications?.length &&
        !onboardingData?.techCustomCertifications?.length
      }
      nextAction={nextAction}
      previousAction={previousAction}
      stepKeys={['techCertifications', 'techCustomCertifications']}
      onboardingWrapper={onboardingWrapper}
      onboardingWrapperProps={onboardingWrapperProps}
      validateBeforeSubmit={() => true}
    >
      <OnboardingTitle $reducedFontSize $additionalTopSpacing>
        What certifications and licenses do you have?
      </OnboardingTitle>
      <OnboardingSubtitle $noTopMargin>
        <Center>Please select all that apply</Center>
      </OnboardingSubtitle>
      <InputWrapper>
        <Input
          startEnhancer={<LucideIcon name="Search" size="unit16" />}
          placeholder="Look up certification or license"
          value={filterCertificatesValue}
          onChange={(e) => setFilterCertificatesValue(e.target.value)}
        />
      </InputWrapper>
      {techCertifications
        .filter((filterOption) =>
          Boolean(
            `${filterOption.display_title}`.match(
              new RegExp(filterCertificatesValue, 'i')
            ) ||
              `${filterOption.internal_title}`.match(
                new RegExp(filterCertificatesValue, 'i')
              )
          )
        )
        .map(
          (option: {
            id: number;
            display_title: string;
            internal_title: string;
          }) => (
            <Checkbox
              key={option.id}
              ariaLabel={option.display_title}
              checked={
                pendingSelectedOptionsSet &&
                pendingSelectedOptionsSet.has(option.id)
              }
              onChange={(e) => {
                const newPendingSelectedOptionsSet = structuredClone(
                  pendingSelectedOptionsSet
                );
                if (e.target.checked) {
                  newPendingSelectedOptionsSet.add(option.id);
                } else {
                  newPendingSelectedOptionsSet.delete(option.id);
                }
                setPendingSelectedOptionsSet(newPendingSelectedOptionsSet);
              }}
              size="large"
            >
              <Text variant="ParagraphMedium">{option.display_title}</Text>
              <Text variant="ParagraphXSmall">{option.internal_title}</Text>
            </Checkbox>
          )
        )}

      {pendingCustomCertifications.map((certification, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <InputWrapper key={index}>
          <Checkbox
            checked={certification.enabled}
            alignItems="center"
            fullWidthLabel
            onChange={(e) => {
              const newPendingCustomCertifications = structuredClone(
                pendingCustomCertifications
              );
              newPendingCustomCertifications[index] = {
                ...certification,
                enabled: e.target.checked,
              };
              setPendingCustomCertifications(newPendingCustomCertifications);
            }}
          >
            <Input
              disabled={!certification.enabled}
              placeholder="Certification or License"
              value={certification.description}
              onChange={(e) => {
                const newPendingCustomCertifications = [
                  ...pendingCustomCertifications,
                ];
                newPendingCustomCertifications[index] = {
                  ...certification,
                  description: e.target.value,
                };
                setPendingCustomCertifications(newPendingCustomCertifications);
              }}
            />
          </Checkbox>
        </InputWrapper>
      ))}

      <Button
        fullWidth
        kind="minimal"
        size="compact"
        onClick={() => {
          setPendingCustomCertifications([
            ...pendingCustomCertifications,
            { enabled: true, description: '' },
          ]);
        }}
      >
        <PlusIcon style={{ marginRight: '8px' }} />
        Add another certification
      </Button>
    </Onboarding>
  );
};
OnboardingTechCertifications.propTypes = {
  nextAction: PropTypes.string,
  onboardingWrapper: PropTypes.func,
  onboardingWrapperProps: PropTypes.object,
  previousAction: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
};

export default OnboardingTechCertifications;

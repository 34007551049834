import React from 'react';
import PropTypes from 'prop-types';

import { styled, withStyle } from 'shared/components/ihcl/styled';
import { expandBorderStyles } from 'baseui/styles/util';

export const FootnoteContent = styled('div', ({ $theme, styles }) => ({
  borderRadius: $theme.borders.footnoteBorderRadius,
  ...expandBorderStyles({
    borderWidth: $theme.borders.footnoteBorderWidth,
    borderColor: $theme.colors.accent,
    borderStyle: 'solid',
  }),
  borderTopStyle: 'solid',
  borderRightStyle: 'solid',
  borderBottomStyle: 'solid',
  borderLeftStyle: 'solid',
  paddingTop: '22px',
  paddingRight: '15px',
  paddingBottom: '19px',
  paddingLeft: '15px',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontSize: '14px',
  fontWeight: 400,
  color: $theme.colors.grayBodyCopy,
  ...styles,
}));

export const FootnoteIndicator = styled('span', ({ $theme }) => ({
  color: $theme.colors.accent,
}));

export const FootnoteContainerIndicator = withStyle(
  FootnoteIndicator,
  ({ $theme, $backgroundColor }) => ({
    position: 'relative',
    width: '1em',
    height: '0.75em',
    marginBottom: '-0.3em',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: $backgroundColor || $theme.colors.white,
    fontSize: '36px',
    lineHeight: '48px',
    textAlign: 'center',
  })
);

export const FootnoteContainer = styled('div', {
  marginTop: '1em',
});

// eslint-disable-next-line react/require-default-props
export const Footnote = ({ children, styles, backgroundColor }) => (
  <FootnoteContainer>
    <FootnoteContainerIndicator $as="div" $backgroundColor={backgroundColor}>
      *
    </FootnoteContainerIndicator>
    <FootnoteContent styles={styles}>{children}</FootnoteContent>
  </FootnoteContainer>
);
Footnote.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  styles: PropTypes.object,
};
Footnote.defaultProps = {
  backgroundColor: null,
  styles: {},
};
